import { format } from '../utils/dates';

export const types = {
  TEACHERS_READ_REQUEST: 'teachers/TEACHERS_READ_REQUEST',
  TEACHERS_READ_FAILURE: 'teachers/TEACHERS_READ_FAILURE',
  TEACHERS_READ_SUCCESS: 'teachers/TEACHERS_READ_SUCCESS',
  TEACHERS_SEARCH_REQUEST: 'teachers/TEACHERS_SEARCH_REQUEST',
  TEACHERS_SEARCH_FAILURE: 'teachers/TEACHERS_SEARCH_FAILURE',
  TEACHERS_SEARCH_SUCCESS: 'teachers/TEACHERS_SEARCH_SUCCESS',
  TEACHERS_CLASS_REQUEST: 'teachers/TEACHERS_CLASS_REQUEST',
  TEACHERS_CLASS_FAILURE: 'teachers/TEACHERS_CLASS_FAILURE',
  TEACHERS_CLASS_SUCCESS: 'teachers/TEACHERS_CLASS_SUCCESS',
  TEACHERS_NEAREST_REQUEST: 'teachers/TEACHERS_NEAREST_REQUEST',
  TEACHERS_NEAREST_FAILURE: 'teachers/TEACHERS_NEAREST_FAILURE',
  TEACHERS_NEAREST_SUCCESS: 'teachers/TEACHERS_NEAREST_SUCCESS',
  TEACHERS_RESERVATION_REQUEST: 'teachers/TEACHERS_RESERVATION_REQUEST',
  TEACHERS_RESERVATION_FAILURE: 'teachers/TEACHERS_RESERVATION_FAILURE',
  TEACHERS_RESERVATION_SUCCESS: 'teachers/TEACHERS_RESERVATION_SUCCESS',
  TEACHERS_CATEGORY_REQUEST: 'teachers/TEACHERS_CATEGORY_REQUEST',
  TEACHERS_CATEGORY_FAILURE: 'teachers/TEACHERS_CATEGORY_FAILURE',
  TEACHERS_CATEGORY_SUCCESS: 'teachers/TEACHERS_CATEGORY_SUCCESS',
  SET_SELECTED_DETAIL_TEACHER: 'teachers/SET_SELECTED_DETAIL_TEACHER',
  TEACHERS_CLEAN_MESSAGE: 'teachers/TEACHERS_CLEAN_MESSAGE',
  TEACHERS_GET_FUTURE_RESERVATION_DATES_REQUEST:
    'teachers/TEACHERS_GET_FUTURE_RESERVATION_DATES_REQUEST',
  TEACHERS_GET_FUTURE_RESERVATION_DATES_FAILURE:
    'teachers/TEACHERS_GET_FUTURE_RESERVATION_DATES_FAILURE',
  TEACHERS_GET_FUTURE_RESERVATION_DATES_SUCCESS:
    'teachers/TEACHERS_GET_FUTURE_RESERVATION_DATES_SUCCESS',
  TEACHERS_GET_OLD_RESERVATION_DATES_REQUEST:
    'teachers/TEACHERS_GET_OLD_RESERVATION_DATES_REQUEST',
  TEACHERS_GET_OLD_RESERVATION_DATES_FAILURE:
    'teachers/TEACHERS_GET_OLD_RESERVATION_DATES_FAILURE',
  TEACHERS_GET_OLD_RESERVATION_DATES_SUCCESS:
    'teachers/TEACHERS_GET_OLD_RESERVATION_DATES_SUCCESS',
  TEACHERS_GET_RESERVATIONS_REQUEST: 'teachers/TEACHERS_GET_RESERVATIONS_REQUEST',
  TEACHERS_GET_RESERVATIONS_FAILURE: 'teachers/TEACHERS_GET_RESERVATIONS_FAILURE',
  TEACHERS_GET_RESERVATIONS_SUCCESS: 'teachers/TEACHERS_GET_RESERVATIONS_SUCCESS',
  TEACHERS_GET_RESERVATION_PROFILE_REQUEST:
    'teachers/TEACHERS_GET_RESERVATION_PROFILE_REQUEST',
  TEACHERS_GET_RESERVATION_PROFILE_FAILURE:
    'teachers/TEACHERS_GET_RESERVATION_PROFILE_FAILURE',
  TEACHERS_GET_RESERVATION_PROFILE_SUCCESS:
    'teachers/TEACHERS_GET_RESERVATION_PROFILE_SUCCESS',
  TEACHERS_DELETE_AGREE_SCHEDULE_REQUEST:
    'teachers/TEACHERS_DELETE_AGREE_SCHEDULE_REQUEST',
  TEACHERS_DELETE_AGREE_SCHEDULE_FAILURE:
    'teachers/TEACHERS_DELETE_AGREE_SCHEDULE_FAILURE',
  TEACHERS_DELETE_AGREE_SCHEDULE_SUCCESS:
    'teachers/TEACHERS_DELETE_AGREE_SCHEDULE_SUCCESS',
  TEACHERS_REGISTER_SCHOOL_REQUEST: 'teachers/TEACHERS_REGISTER_SCHOOL_REQUEST',
  TEACHERS_REGISTER_SCHOOL_FAILURE: 'teachers/TEACHERS_REGISTER_SCHOOL_FAILURE',
  TEACHERS_REGISTER_SCHOOL_SUCCESS: 'teachers/TEACHERS_REGISTER_SCHOOL_SUCCESS'
};

export const INITIAL_STATE = {
  selectedDetailTeacher: {},
  nearestTeachers: [],
  teachers: [],
  searchs: [],
  isFetching: false,
  message: '',
  classes: [],
  categories: [],
  futureReservationDates: [],
  oldReservationDates: []
};

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  switch (action.type) {
    case types.TEACHERS_CLEAN_MESSAGE:
      return { ...state, message: null };
    case types.TEACHERS_READ_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_READ_FAILURE:
      return { ...state, isFetching: false, message: action.message, teachers: [] };
    case types.TEACHERS_READ_SUCCESS:
      return { ...state, isFetching: false, teachers: action.teachers };
    case types.TEACHERS_SEARCH_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_SEARCH_FAILURE:
      return { ...state, isFetching: false, message: action.message, searchs: [] };
    case types.TEACHERS_SEARCH_SUCCESS:
      return { ...state, isFetching: false, searchs: action.searchs };
    case types.TEACHERS_CLASS_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_CLASS_FAILURE:
      return { ...state, isFetching: false, message: action.message, classes: [] };
    case types.TEACHERS_CLASS_SUCCESS:
      return { ...state, isFetching: false, classes: action.classes };
    case types.TEACHERS_NEAREST_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_NEAREST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        nearestTeachers: []
      };
    case types.TEACHERS_NEAREST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        nearestTeachers: action.nearestTeachers
      };
    case types.TEACHERS_RESERVATION_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_RESERVATION_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_RESERVATION_SUCCESS:
      return { ...state, isFetching: false };
    case types.TEACHERS_CATEGORY_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_CATEGORY_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_CATEGORY_SUCCESS:
      return { ...state, isFetching: false, categories: action.categories };
    case types.TEACHERS_GET_FUTURE_RESERVATION_DATES_REQUEST:
      return { ...state, isFetching: true, message: '', futureReservationDates: [] };
    case types.TEACHERS_GET_FUTURE_RESERVATION_DATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        futureReservationDates: []
      };
    case types.TEACHERS_GET_FUTURE_RESERVATION_DATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        futureReservationDates: action.futureReservationDates
      };
    case types.TEACHERS_GET_OLD_RESERVATION_DATES_REQUEST:
      return { ...state, isFetching: true, message: '', oldReservationDates: [] };
    case types.TEACHERS_GET_OLD_RESERVATION_DATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        oldReservationDates: []
      };
    case types.TEACHERS_GET_OLD_RESERVATION_DATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        oldReservationDates: action.oldReservationDates
      };
    case types.TEACHERS_GET_RESERVATIONS_REQUEST:
      return { ...state, isFetching: true, message: '', reservations: [] };
    case types.TEACHERS_GET_RESERVATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
        reservations: []
      };
    case types.TEACHERS_GET_RESERVATIONS_SUCCESS:
      return { ...state, isFetching: false, reservations: action.reservations };
    case types.TEACHERS_GET_RESERVATION_PROFILE_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_GET_RESERVATION_PROFILE_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_GET_RESERVATION_PROFILE_SUCCESS:
      return { ...state, isFetching: false };
    case types.TEACHERS_DELETE_AGREE_SCHEDULE_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_DELETE_AGREE_SCHEDULE_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_DELETE_AGREE_SCHEDULE_SUCCESS:
      return { ...state, isFetching: false };
    case types.TEACHERS_REGISTER_SCHOOL_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_REGISTER_SCHOOL_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_REGISTER_SCHOOL_SUCCESS:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export const actions = {
  teachersRequest: () => ({
    type: types.TEACHERS_READ_REQUEST
  }),
  teachersFailure: (message) => ({
    type: types.TEACHERS_READ_FAILURE,
    message
  }),
  teachersSuccess: (teachers) => ({
    type: types.TEACHERS_READ_SUCCESS,
    teachers
  }),
  searchRequest: () => ({
    type: types.TEACHERS_SEARCH_REQUEST
  }),
  searchFailure: (message) => ({
    type: types.TEACHERS_SEARCH_FAILURE,
    message
  }),
  searchSuccess: (searchs) => ({
    type: types.TEACHERS_SEARCH_SUCCESS,
    searchs
  }),
  createClassRequest: () => ({
    type: types.TEACHERS_CLASS_REQUEST
  }),
  createClassFailure: (message) => ({
    type: types.TEACHERS_CLASS_FAILURE,
    message
  }),
  createClassSuccess: (classes) => ({
    type: types.TEACHERS_CLASS_SUCCESS,
    classes
  }),
  teachersNearestRequest: () => ({
    type: types.TEACHERS_NEAREST_REQUEST
  }),
  teachersNearestFailure: (message) => ({
    type: types.TEACHERS_NEAREST_FAILURE,
    message
  }),
  teachersNearestSuccess: (nearestTeachers) => ({
    type: types.TEACHERS_NEAREST_SUCCESS,
    nearestTeachers
  }),
  createReservationRequest: () => ({
    type: types.TEACHERS_RESERVATION_REQUEST
  }),
  createReservationFailure: (message) => ({
    type: types.TEACHERS_RESERVATION_FAILURE,
    message
  }),
  createReservationSuccess: () => ({
    type: types.TEACHERS_RESERVATION_SUCCESS
  }),
  categoryRequest: () => ({
    type: types.TEACHERS_CATEGORY_REQUEST
  }),
  categoryFailure: (message) => ({
    type: types.TEACHERS_CATEGORY_FAILURE,
    message
  }),
  categorySuccess: (categories) => ({
    type: types.TEACHERS_CATEGORY_SUCCESS,
    categories
  }),
  modifyClassRequest: () => ({
    type: types.TEACHERS_CLASS_REQUEST
  }),
  modifyClassFailure: (message) => ({
    type: types.TEACHERS_CLASS_FAILURE,
    message
  }),
  modifyClassSuccess: ({ params, date, category, single }, classes) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < classes.length; i++) {
      if (
        format(classes[i].date) === date &&
        classes[i].identify === params.identify &&
        classes[i].category === category
      ) {
        classes.splice(i, 1);
        break;
      }
    }

    return {
      type: types.TEACHERS_CLASS_SUCCESS,
      classes: [...classes, { ...single, ...params }]
    };
  },
  reset: () => ({
    type: types.TEACHERS_CLEAN_MESSAGE
  }),
  getFutureReservationDatesRequest: () => ({
    type: types.TEACHERS_GET_FUTURE_RESERVATION_DATES_REQUEST
  }),
  getFutureReservationDatesFailure: (message) => ({
    type: types.TEACHERS_GET_FUTURE_RESERVATION_DATES_FAILURE,
    message
  }),
  getFutureReservationDatesSuccess: (futureReservationDates) => ({
    type: types.TEACHERS_GET_FUTURE_RESERVATION_DATES_SUCCESS,
    futureReservationDates
  }),
  getOldReservationDatesRequest: () => ({
    type: types.TEACHERS_GET_OLD_RESERVATION_DATES_REQUEST
  }),
  getOldReservationDatesFailure: (message) => ({
    type: types.TEACHERS_GET_OLD_RESERVATION_DATES_FAILURE,
    message
  }),
  getOldReservationDatesSuccess: (oldReservationDates) => ({
    type: types.TEACHERS_GET_OLD_RESERVATION_DATES_SUCCESS,
    oldReservationDates
  }),
  getReservationsRequest: () => ({
    type: types.TEACHERS_GET_RESERVATIONS_REQUEST
  }),
  getReservationsFailure: (message) => ({
    type: types.TEACHERS_GET_RESERVATIONS_FAILURE,
    message
  }),
  getReservationsSuccess: (reservations) => ({
    type: types.TEACHERS_GET_RESERVATIONS_SUCCESS,
    reservations
  }),
  getReservationProfileRequest: () => ({
    type: types.TEACHERS_GET_RESERVATION_PROFILE_REQUEST
  }),
  getReservationProfileFailure: (message) => ({
    type: types.TEACHERS_GET_RESERVATION_PROFILE_FAILURE,
    message
  }),
  getReservationProfileSuccess: () => ({
    type: types.TEACHERS_GET_RESERVATION_PROFILE_SUCCESS
  }),
  deleteAgreeScheduleRequest: () => ({
    type: types.TEACHERS_DELETE_AGREE_SCHEDULE_REQUEST
  }),
  deleteAgreeScheduleFailure: (message) => ({
    type: types.TEACHERS_DELETE_AGREE_SCHEDULE_FAILURE,
    message
  }),
  deleteAgreeScheduleSuccess: () => ({
    type: types.TEACHERS_DELETE_AGREE_SCHEDULE_SUCCESS
  }),
  registerSchoolRequest: () => ({
    type: types.TEACHERS_REGISTER_SCHOOL_REQUEST
  }),
  registerSchoolFailure: (message) => ({
    type: types.TEACHERS_REGISTER_SCHOOL_FAILURE,
    message
  }),
  registerSchoolSuccess: () => ({
    type: types.TEACHERS_REGISTER_SCHOOL_SUCCESS
  })
};

export const selectors = {
  getNearest: ({ teachers }) => teachers.nearestTeachers,
  getTeachers: ({ teachers }) => teachers.teachers,
  getSearchs: ({ teachers }) => teachers.searchs,
  isFetching: ({ teachers }) => teachers.isFetching,
  getClasses: ({ teachers }) => teachers.classes,
  getMessage: ({ teachers }) => teachers.message,
  getCategories: ({ teachers }) => teachers.categories,
  getFutureReservationDates: ({ teachers }) => teachers.futureReservationDates,
  getOldReservationDates: ({ teachers }) => teachers.oldReservationDates,
  getReservations: ({ teachers }) => teachers.reservations
};
