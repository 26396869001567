/* eslint-disable no-restricted-globals */
// CONSTANTS
export const constants = {
  MIN_VALUE: 3
};

export const emailValidation = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (emailRegex.test(email) && !email.includes(' ')) {
    return true;
  }
  return false;
};

export const inputValidation = (value) => {
  if (!value.includes('  ') && value.length >= constants.MIN_VALUE) {
    return true;
  }
  return false;
};

export const inputNumberValidation = (value) => {
  if (!isNaN(value) && parseInt(value, 10) >= 0 && !value.includes('.')) {
    return true;
  }
  return false;
};

export const passwordValidation = (value) => {
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  if (passwordRegex.test(value) && !value.includes(' ')) {
    return true;
  }
  return false;
};

export const dateObjectValidation = (object) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const date = new Date(object.year, object.month - 1, object.day);
    if (
      isNaN(date.getTime()) ||
      date.getDate() !== parseInt(object.day, 10) ||
      date.getMonth() !== parseInt(object.month - 1, 10) ||
      date.getFullYear() !== parseInt(object.year, 10)
    ) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const dayValidation = (value) => {
  const regex = new RegExp('^(?=.*[1-9])');
  if (regex.test(value) && !value.includes(' ') && parseInt(value, 10) <= 31) {
    return true;
  }
  return false;
};

export const yearValidation = (value) => {
  const regex = new RegExp('^(?=.*[1-9])(?=.{4,})');
  if (regex.test(value) && !value.includes(' ')) {
    return true;
  }
  return false;
};

export const monthValidation = (value) => {
  const regex = new RegExp('^(?=.*[1-9])');
  if (
    regex.test(value) &&
    !value.includes(' ') &&
    dateObjectValidation({ day: 1, month: value, year: 2000 })
  ) {
    return true;
  }
  return false;
};
