// Principal libraries
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

// Components
import PrivateRoute from './PrivateRoute';
import Modal from '../components/Modal';
import Auth from '../pages/home/auth/Auth';
import Fetching from '../components/Fetching';
import ModalFetching from '../components/ModalFetching';

// Extras
import { selectors, actions } from '../reducers/account';

// Pages
import Home from '../pages/home/Home';
import Search from '../pages/search/Search';
import Account from '../pages/account/Account';
import Profile from '../pages/profile/Profile';
import Service from '../pages/services/Service';
import Footer from '../pages/home/footer/Footer';
import Stream from '../pages/stream/Stream';
import Receptor from '../pages/receptors/ReceptorRTMP';
import Menu from '../components/menu/Menu';
import Register from '../pages/register/Register';
import Download from '../pages/download/Download';

const Navigator = () => {
  const dispatch = useDispatch();
  const { showModal, isLogged } = useSelector((state) => ({
    isLogged: selectors.isLogged(state),
    showModal: selectors.showLogin(state)
  }));
  const { show } = useSelector(selectors.showModal);
  const reducers = useSelector((state) => state);
  const [fetch, setFetch] = useState(false);
  const handleModal = () => dispatch(actions.showLogin(!showModal));

  useEffect(() => {
    let isFetching = false;
    Object.keys(reducers).forEach((item) => {
      if (reducers[item].isFetching && reducers[item].isFetching === true) {
        isFetching = true;
      }
    });
    setFetch(isFetching);
  }, [reducers]);

  return (
    <Router>
      <>
        <Modal
          width="40%"
          border="20px"
          open={showModal}
          onClick={handleModal}
          title="Iniciar sesión"
          zIndex="2"
          fetch={fetch}
        >
          <Auth />
        </Modal>
        <ModalFetching />
        <Fetching show={fetch} />
        <Container fetch={fetch || show}>
          <Content>
            <Menu />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/descarga" component={Download} />
              <PrivateRoute exact path="/stream" component={Stream} />
              <Route exact path="/online/:id" component={Receptor} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/account" component={Account} />
              <PrivateRoute
                exact
                path="/notifications"
                component={() => <p>Notificaciones pendiente</p>}
              />
              {/* <PrivateRoute exact path="/messages" component={Message} /> */}
              <PrivateRoute exact path="/services" component={Service} />
              <PrivateRoute
                exact
                path="/bills"
                component={() => <p>Facturación pendiente</p>}
              />
              <PrivateRoute
                exact
                path="/valuations"
                component={() => <p>Valoraciones pendiente</p>}
              />
              <PrivateRoute
                exact
                path="/offer"
                component={() => <p>Quiero brindar servicios pendiente</p>}
              />
              <PrivateRoute
                exact
                path="/remove"
                component={() => <p>Quiero dejar de brindar servicios pendiente</p>}
              />
              <Route exact path="/register" component={Register} />
              <PrivateRoute
                exact
                path="/schedule"
                component={() => <p>Agenda pendiente</p>}
              />
              <Route
                exact
                path="/promotions"
                component={() => <p>Promociones pendiente</p>}
              />
              <Route exact path="/shop" component={() => <p>Tienda pendiente</p>} />
              <Route exact path="/search/:id" component={Search} />
              <Route path="*" component={() => <p>404</p>} />
            </Switch>
            <Footer />
          </Content>
        </Container>
      </>
    </Router>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  filter: ${(props) => (props.fetch ? 'blur(2px)' : 'none')};
  -webkit-filter: ${(props) => (props.fetch ? 'blur(2px)' : 'none')};
`;

const Content = styled.div`
  display: inline-block;
  flex: 1;
`;

export default Navigator;
