import * as API from './api';

const URL = '/api/sessions';

export const updateSession = (params) =>
  API.executeAnonymous(`${URL}/updateSession`, params);

export const logout = (params) => API.executeAnonymous(`${URL}/logout`, params);

export const verifyUsername = (params) =>
  API.executeAnonymous(`${URL}/verifyUsername`, params);

export const login = (params) => API.executeAnonymous(`${URL}/login`, params);

export const resetPasswordRequest = (params) =>
  API.executeAnonymous(`${URL}/resetPasswordRequest`, params);

export const resetPassword = (params) =>
  API.executeAnonymous(`${URL}/resetPassword`, params);

export const registerToken = (params) =>
  API.executeAnonymous(`${URL}/registerToken`, params);

export const deleteAccount = (params) =>
  API.executeAnonymous(`${URL}/deleteAccount`, params);
