// REFACTOR/TODO
// Principal libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Extras
import actions from '../../../../actions/teachers';
import {
  selectors as accountSelectors,
  actions as accountActions
} from '../../../../reducers/account';
import { fonts } from '../../../../utils/constants';

const Schedule = ({ item, setModal, modal, data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => ({
    account: accountSelectors.getAccount(state)
  }));
  const [stepState, setStepState] = useState(0);
  const [hours, setHours] = useState(1);

  useEffect(() => {
    dispatch(actions.getCategories({ identify: item.id }));
  }, []);

  const handleContract = () => {
    setModal({ ...modal, title: '' });
    setStepState(1);
  };

  const handleConfirm = () => {
    if (Object.keys(account).length) {
      dispatch(
        actions.createReservation({
          price: item.price,
          hours,
          idTeacher: item.id,
          isFixed: false
        })
      );
      setStepState(2);
    } else {
      dispatch(accountActions.showLogin(true));
    }
  };

  const handleHour = (hour) => () => {
    setHours(hour);
  };

  const HourButton = styled.button`
    background-color: ${(props) => (props.option === hours ? '#0E67BE' : 'gray')};
    color: white;
    padding: 30px;
    border-radius: 50px;
    margin: 20px;
    outline: none;
    border: none;
  `;

  const handleContact = () => {
    history.push('/messages');
  };

  return (
    <Container>
      {stepState === 0 ? (
        <Content>
          <Input disabled value={data.agreeschedule} />
          <Line higher>
            <Text>Valor del servicio </Text>
            <Text symbol>$</Text>
            <Price>{item.price}</Price>
          </Line>
          <ContractButton onClick={handleContract}>Contratar</ContractButton>
        </Content>
      ) : null}
      {stepState === 1 ? (
        <Content>
          <Line higher>
            <Text>Ya estas mas cerca de adquirir conocimiento</Text>
          </Line>
          <Text color="darkgray">Seleccione la cantidad de horas</Text>
          <Line higher>
            <HourButton option={1} onClick={handleHour(1)}>
              1
            </HourButton>
            <HourButton option={2} onClick={handleHour(2)}>
              2
            </HourButton>
          </Line>
          <ContractButton onClick={handleConfirm}>Siguiente</ContractButton>
        </Content>
      ) : null}
      {stepState === 2 ? (
        <Content>
          <Align>
            <SuccessTitle>Felicidades!</SuccessTitle>
            <SuccessSubTitle>Contrataste un profesor</SuccessSubTitle>
            <SuccessDescription>
              Puedes chatear ahora presionando "contactarte ahora" o más tarde,
              accediendo al panel de "chats"
            </SuccessDescription>
            <SuccessNote>Recuerda que el pago se realiza en persona</SuccessNote>
            <LinkButton onClick={handleContact}>Contactarte ahora</LinkButton>
          </Align>
        </Content>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 20px;
  justify-content: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const Align = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.higher ? 'center' : 'flex-start')};
  align-items: center;
  width: ${(props) => (props.higher ? '100%' : '80%')};
  margin: 20px 5px;
`;

const Text = styled.p`
  color: ${(props) => (props.color ? props.color : '#021944')};
  margin: 0;
  font-weight: bold;
  margin-left: ${(props) => (props.symbol ? '10px' : '0px')};
`;

const Price = styled.p`
  color: #1692ff;
  font-weight: bold;
  margin: 0px 10px;
  border-bottom: 1px solid #bac4f2;
  padding: 0px 20px;
`;

const SuccessTitle = styled.p`
  margin: 0;
  font-size: 1.4em;
  font-weight: bold;
  color: #1692ff;
`;

const SuccessSubTitle = styled.p`
  margin: 0;
  font-size: 1.4em;
  font-weight: bold;
  color: #bac4f2;
  margin-bottom: 10px;
`;

const SuccessDescription = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  color: #bac4f2;
  margin: 20px 0;
`;

const SuccessNote = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  color: #1692ff;
  margin-bottom: 20px;
`;

const Input = styled.textarea`
  flex: 1;
  width: 85%;
  background-color: #1d233b;
  color: white;
  font-family: ${fonts.principal};
  font-size: 1em;
  padding: 10px;
  resize: none;
  border: 1px solid #bac4f2;
  border-radius: 5px;
`;

const ContractButton = styled.button`
  background-color: #1692ff;
  color: white;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 80%;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px 0;
`;

const LinkButton = styled(Link)`
  background-color: white;
  color: #011220;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 80%;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px auto;
  text-align: center;
`;

export default Schedule;
