import { trimObject } from '../utils/strings';
import { PORT, IP, PROTOCOL } from './constants';
import { store } from '../store';

const anonymousHeaders = (params) => {
  trimObject(params);
  return {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-Access-Token': store.getState().account.token,
      'register-access-token': store.getState().account.registerToken
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(params)
  };
};

export const executeAnonymous = async (route, params) => {
  return fetch(`${PROTOCOL}://${IP}:${PORT}${route}`, anonymousHeaders(params)).then(
    async (response) => {
      const json = await response.json();
      if (json.status === 401) {
        throw new Error('Unauthorized');
      } else {
        if (json.status !== 200) throw json.error;
        return json;
      }
    }
  );
};

export const executeCheck = (route, params) => {
  return fetch(`${PROTOCOL}://${IP}:${PORT}${route}`, anonymousHeaders(params))
    .then((response) => response.json())
    .catch(() => ({
      error: 'No se ha podido conectar con el servidor'
    }));
};