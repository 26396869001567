import {keyframes} from 'styled-components';

const ZoomIn = keyframes` {
    0% {
       opacity: 0;
       transform: scale(1.2);
    }
    50% {
       opacity: 1;
       transform: scale(.9);
    }
  
  100% {
       opacity: 1;
       transform: scale(1);
    
  } 
`; 


  const FadeInLeft = keyframes` {
  0% {
     opacity: 0;
     transform: translateX(-950px);
  }
  50% {
     opacity: 1;
     transform: translateX(40px);
  
} 
100% {
     opacity: 1;
     transform: translateX(0px);
  
}`; 


  export  {ZoomIn, FadeInLeft};