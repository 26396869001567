// REFACTOR/TODO
// Principal libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Box from './components/Box';

// Extras
import actions from '../../../../actions/teachers';
import { selectors } from '../../../../reducers/teachers';
import {
  selectors as accountSelectors,
  actions as accountActions
} from '../../../../reducers/account';
import { fonts } from '../../../../utils/constants';

const Contract = ({ item, setStep, setModal, modal }) => {
  const dispatch = useDispatch();
  const { categories, account, classes } = useSelector((state) => ({
    categories: selectors.getCategories(state),
    account: accountSelectors.getAccount(state),
    classes: selectors.getClasses(state)
  }));
  const history = useHistory();
  const [stepState, setStepState] = useState(0);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    dispatch(actions.getCategories({ identify: item.id }));
    dispatch(actions.getFixedClass({ identify: item.id, category }));
  }, []);

  const handleClick = (item) => () => {
    setModal({ ...modal, title: item.category });
    setCategory(item);
    setStepState(1);
  };

  const handleContract = () => {
    setModal({ ...modal, title: '' });
    setStepState(2);
  };

  const handleConfirm = () => {
    if (Object.keys(account).length) {
      dispatch(
        actions.createReservation({
          price: item.price,
          hours: 1,
          idTeacher: item.id,
          isFixed: true,
          quantity: 1,
          category
        })
      );
      setStepState(3);
    } else {
      dispatch(accountActions.showLogin(true));
    }
  };

  const handleContact = () => {
    history.push('/messages');
  };

  return (
    <Container>
      {stepState === 0
        ? categories.map((item) => (
            <Box
              key={item.category}
              title={item.category}
              onClick={handleClick(item)}
            />
          ))
        : null}
      {stepState === 1 ? (
        <Content>
          <Input disabled value={item.description} />
          <Line higher>
            <Text>Valor de la materia </Text>
            <Text symbol>$</Text>
            <Price>{item.price}</Price>
            <Text>por hora</Text>
          </Line>
          <ContractButton onClick={handleContract}>Contratar</ContractButton>
        </Content>
      ) : null}
      {stepState === 2 ? (
        <Content>
          <Line>
            <TitleSer>Contrataras:</TitleSer>
            <DescSer>{`"${category.category}"`}</DescSer>
          </Line>
          <Line>
            <TitleSer>Otorga la clase:</TitleSer>
            <DescSer>{`"${item.name} ${item.surname}"`}</DescSer>
          </Line>
          <Line>
            <TitleSer>Precio de la materia:</TitleSer>
            <DescSer>{category.price > 0 && `$ ${category.price} por hora`}</DescSer>
          </Line>
          <Separation />
          <ContractButton onClick={handleConfirm}>Confirmar</ContractButton>
        </Content>
      ) : null}
      {stepState === 3 ? (
        <Content>
          <Align>
            <SuccessTitle>Felicidades!</SuccessTitle>
            <SuccessSubTitle>Contrataste un profesor</SuccessSubTitle>
            <SuccessDescription>
              Puedes chatear ahora presionando "contactarte ahora" o más tarde,
              accediendo al panel de "chats"
            </SuccessDescription>
            <SuccessNote>Recuerda que el pago se realiza en persona</SuccessNote>
            <LinkButton onClick={handleContact}>Contactarte ahora</LinkButton>
          </Align>
        </Content>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 20px;
  justify-content: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const Align = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.higher ? 'center' : 'flex-start')};
  align-items: center;
  width: ${(props) => (props.higher ? '100%' : '80%')};
  margin: 20px 5px;
`;

const Separation = styled.div`
  width: 90%;
  height: 1px;
  background-color: white;
  border-radius: 2px;
  margin: 50px 0px;
`;

const TitleSer = styled.p`
  color: #bac4f2;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
`;

const DescSer = styled.p`
  color: #1692ff;
  font-weight: bold;
  margin: 0px 10px;
  text-transform: uppercase;
`;

const Text = styled.p`
  color: #bac4f2;
  margin: 0;
  font-weight: bold;
  margin-left: ${(props) => (props.symbol ? '10px' : '0px')};
`;

const Price = styled.p`
  color: #1692ff;
  font-weight: bold;
  margin: 0px 10px;
  border-bottom: 1px solid #bac4f2;
  padding: 0px 20px;
`;

const SuccessTitle = styled.p`
  margin: 0;
  font-size: 1.4em;
  font-weight: bold;
  color: #1692ff;
`;

const SuccessSubTitle = styled.p`
  margin: 0;
  font-size: 1.4em;
  font-weight: bold;
  color: #bac4f2;
  margin-bottom: 10px;
`;

const SuccessDescription = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  color: #bac4f2;
  margin: 20px 0;
`;

const SuccessNote = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  color: #1692ff;
  margin-bottom: 20px;
`;

const Input = styled.textarea`
  flex: 1;
  width: 85%;
  background-color: #1d233b;
  color: white;
  font-family: ${fonts.principal};
  font-size: 1em;
  padding: 10px;
  resize: none;
  border: 1px solid #bac4f2;
  border-radius: 5px;
`;

const ContractButton = styled.button`
  background-color: #1692ff;
  color: white;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 80%;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px 0;
`;

const LinkButton = styled(Link)`
  background-color: white;
  color: #011220;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 80%;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px auto;
  text-align: center;
`;

export default Contract;
