// Actions
import { actions as accountActions } from '../reducers/account';
import { actions } from '../reducers/teachers';
// Middleware
import {
  getTeachers,
  setTeacher,
  getRate,
  getAgreeSchedule,
  modify,
  getCategories,
  createFixedHour,
  getFixed,
  createReservation,
  deleteCategory,
  addCategories,
  getNearestTeachers,
  modifyClass,
  getChannels,
  getChats,
  sendMessage,
  getPayments,
  getReservationDates,
  getReservations,
  getReservationProfileById,
  deleteTeacher,
  registerSchoolTeacher
} from '../middleware/teachers';
import { uploadImage } from '../middleware/files';
import { removeObject } from '../utils/arrays';
import { format } from '../utils/dates';
import reportProblem from '../middleware/reports';

export default {
  registerSchoolTeacher: (params) => async (dispatch, getState) => {
    dispatch(actions.registerSchoolRequest());
    try {
      const { photo } = params;
      let file = {};
      if (photo) {
        file = await uploadImage(photo);
      }
      const { data, error } = await registerSchoolTeacher({
        ...params,
        photo: file.data
      });
      if (error) {
        dispatch(actions.registerSchoolFailure(error));
      } else if (data) {
        const { identify, token } = data;
        dispatch(accountActions.setToken(token));
        dispatch(
          accountActions.saveStudentsSuccess({
            ...params,
            identify,
            photo: file.data,
            isTeacher: true,
            rate: 5
          })
        );
        dispatch(actions.registerSchoolSuccess());
        dispatch(accountActions.logged(true));
        alert('Registro exitoso');
      }
    } catch (ex) {
      dispatch(actions.registerSchoolFailure(ex));
    }
  },
  teachersRequest: () => {
    return async (dispatch, getState) => {
      dispatch(actions.teachersRequest());
      const {
        account: { identify = 1 }
      } = getState().account;
      try {
        const { data: teachersWithLocation } = await getTeachers({
          identify
        });
        dispatch(actions.teachersSuccess(teachersWithLocation));
      } catch (err) {
        dispatch(actions.teachersFailure(err));
      }
    };
  },
  teachersSearchRequest: (params) => {
    return (dispatch, getState) => {
      dispatch(actions.searchRequest());
      const {
        account: { identify = 1 },
        currentLocation
      } = getState().account;
      getTeachers({ identify, ...params, ...currentLocation })
        .then(({ data }) => dispatch(actions.searchSuccess(data)))
        .catch((error) => dispatch(actions.searchFailure(error)));
    };
  },
  teacherRate: async (params) => {
    const data = await getRate(params).then((response) => response.data);
    return data;
  },
  teacherAgreeSchedule: async (params) => {
    const data = await getAgreeSchedule(params).then((response) => response.data);
    return data;
  },
  getCategories: (params) => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.categoryRequest());
      try {
        const { error, data } = await getCategories({ identify, ...params });
        if (error) {
          dispatch(actions.categoryFailure(error));
        } else {
          dispatch(actions.categorySuccess(data));
        }
      } catch (error) {
        dispatch(actions.categoryFailure(error));
      }
    };
  },
  addTeacher: (params) => {
    return (dispatch, getState) => {
      const { longitude, latitude } = params;
      const { account } = getState().account;
      dispatch(accountActions.saveStudentsRequest());
      setTeacher({ identify: account.identify, ...params })
        .then(({ error }) => {
          if (error) {
            dispatch(accountActions.saveStudentsFailure(error));
          } else {
            dispatch(
              accountActions.saveStudentsSuccess({
                ...account,
                isTeacher: true,
                longitude,
                latitude,
                address: params.address,
                country: params.country,
                city: params.city,
                departament: params.departament
              })
            );
            // navigation.navigate('ProfileSuccess');
          }
        })
        .catch((error) => dispatch(accountActions.saveStudentsFailure(error)));
    };
  },
  deleteTeacher: (params) => {
    return async (dispatch, getState) => {
      const { account } = getState().account;
      dispatch(accountActions.saveStudentsRequest());
      if (params) {
        await reportProblem({ ...params });
      }
      deleteTeacher({ identify: account.identify })
        .then(({ error }) => {
          if (error) {
            dispatch(accountActions.saveStudentsFailure(error));
          } else {
            dispatch(
              accountActions.saveStudentsSuccess({
                ...account,
                isTeacher: false
              })
            );
            // navigation.navigate('DeleteTeacherSuccess');
          }
        })
        .catch((error) => dispatch(accountActions.saveStudentsFailure(error)));
    };
  },
  modifyTeacher: async (params) => {
    const data = await modify({ ...params }); // ****REVISAR**** //
    return data;
  },
  createClass: (params) => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.createClassRequest());
      createFixedHour({ identify, ...params })
        .then(({ error }) => {
          if (error) {
            dispatch(actions.createClassFailure(error));
          } else {
            dispatch(actions.createClassSuccess([]));
            // navigation.navigate('CreateClassSuccess');
          }
        })
        .catch((error) => dispatch(actions.createClassFailure(error)));
    };
  },
  getFixedClass: (params) => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.createClassRequest());
      getFixed({ identify, ...params })
        .then(({ error, data: { classes } }) => {
          if (error) {
            dispatch(actions.createClassFailure(error));
          } else {
            dispatch(actions.createClassSuccess([...classes]));
          }
        })
        .catch((error) => dispatch(actions.createClassFailure(error)));
    };
  },
  teachersNearestRequest: (params) => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.teachersNearestRequest());
      getNearestTeachers({ ...params, identify })
        .then((response) => {
          dispatch(actions.teachersNearestSuccess(response.data));
        })
        .catch((error) => {
          dispatch(actions.teachersNearestFailure(error));
        });
    };
  },
  createReservation: (params) => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.createReservationRequest());
      createReservation({ idStudent: identify, ...params })
        .then(({ error }) => {
          if (error) {
            dispatch(actions.createReservationFailure(error));
          } else {
            dispatch(actions.createReservationSuccess());
            // navigation.navigate('ReservationSuccessScreen');
          }
        })
        .catch((error) => dispatch(actions.createReservationFailure(error)));
    };
  },
  deleteCategory: (params) => {
    return (dispatch, getState) => {
      const {
        account: {
          account: { identify }
        },
        teachers: { categories }
      } = getState();
      dispatch(actions.categoryRequest());
      removeObject(categories, 'category', params.category);
      deleteCategory({ identify, ...params })
        .then(({ error }) => {
          if (error) {
            dispatch(actions.categoryFailure(error));
          } else {
            dispatch(actions.categorySuccess([...categories]));
          }
        })
        .catch((error) => dispatch(actions.categoryFailure(error)));
    };
  },
  addCategories: (params) => {
    return async (dispatch, getState) => {
      const {
        account: {
          account: { identify }
        },
        teachers: { categories }
      } = getState();
      dispatch(actions.categoryRequest());
      try {
        const { error } = await addCategories({ identify, ...params });
        if (error) {
          dispatch(actions.categoryFailure(error));
        } else {
          dispatch(actions.categorySuccess([...categories, params.category]));
          // navigation.navigate('MyClassScreen');
        }
      } catch (error) {
        dispatch(actions.categoryFailure(error));
      }
    };
  },
  modifyClass: (params) => {
    return async (dispatch, getState) => {
      const { classes } = getState().teachers;
      dispatch(actions.modifyClassRequest());
      try {
        const { error } = await modifyClass({ ...params });
        if (error) {
          dispatch(actions.modifyClassFailure(error));
        } else {
          dispatch(actions.modifyClassSuccess(params, classes));
          // navigation.navigate('FixedHoursScreen');
        }
      } catch (error) {
        dispatch(actions.modifyClassFailure(error));
      }
    };
  },
  getChannels: (params) => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(accountActions.channelRequest());
      try {
        const { error, data } = await getChannels({ identify, ...params });
        if (error) {
          dispatch(accountActions.channelFailure(error));
        } else {
          dispatch(accountActions.channelSuccess([...data]));
        }
      } catch (error) {
        dispatch(accountActions.channelFailure(error));
      }
    };
  },
  getChats: (params) => {
    return async (dispatch, getState) => {
      const {
        account: { identify },
        chats
      } = getState().account;
      dispatch(accountActions.chatRequest());
      try {
        const { error, data } = await getChats({ identify, ...params });
        if (error) {
          dispatch(accountActions.chatFailure(error));
        } else if (chats[params.idChannel]) {
          dispatch(
            accountActions.chatSuccess({
              ...chats,
              [params.idChannel]: [...data, ...chats[params.idChannel]]
            })
          );
        } else {
          dispatch(
            accountActions.chatSuccess({ ...chats, [params.idChannel]: [...data] })
          );
        }
      } catch (error) {
        dispatch(accountActions.chatFailure(error));
      }
    };
  },
  sendMessage: (params) => {
    return async (dispatch, getState) => {
      const {
        account: { chats }
      } = getState();
      const date = new Date();
      dispatch(accountActions.sendMessageRequest());
      try {
        if (chats[params.idChannel]) {
          dispatch(
            accountActions.sendMessageSuccess({
              ...chats,
              [params.idChannel]: [{ date, ...params }, ...chats[params.idChannel]]
            })
          );
        } else {
          dispatch(
            accountActions.sendMessageSuccess({
              ...chats,
              [params.idChannel]: [{ date, ...params }]
            })
          );
        }
      } catch (error) {
        dispatch(accountActions.sendMessageFailure(error));
      }
    };
  },
  setMessage: (params) => {
    return async (dispatch, getState) => {
      const {
        account: { chats }
      } = getState();
      try {
        if (chats[params.idChannel]) {
          dispatch(
            accountActions.sendMessageSuccess({
              ...chats,
              [params.idChannel]: [
                { date: new Date(), ...params },
                ...chats[params.idChannel]
              ]
            })
          );
        } else {
          dispatch(
            accountActions.sendMessageSuccess({
              ...chats,
              [params.idChannel]: [{ date: new Date(), ...params }]
            })
          );
        }
      } catch (error) {
        dispatch(accountActions.sendMessageFailure(error));
      }
    };
  },
  getPayments: (params) => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(accountActions.paymentsRequest());
      try {
        const { error, data } = await getPayments({ identify, ...params });
        if (error) {
          dispatch(accountActions.paymentsFailure(error));
        } else {
          dispatch(accountActions.paymentsSuccess([...data]));
        }
      } catch (error) {
        dispatch(accountActions.paymentsFailure(error));
      }
    };
  },
  getFutureReservationDates: () => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.getFutureReservationDatesRequest());
      try {
        const { error, data } = await getReservationDates({
          identify,
          future: true
        });
        if (error) {
          dispatch(actions.getFutureReservationDatesFailure(error));
        } else {
          dispatch(actions.getFutureReservationDatesSuccess(data));
        }
      } catch (error) {
        dispatch(actions.getFutureReservationDatesFailure(error));
      }
    };
  },
  getOldReservationDates: () => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.getOldReservationDatesRequest());
      try {
        const { error, data } = await getReservationDates({
          identify,
          future: false
        });
        if (error) {
          dispatch(actions.getOldReservationDatesFailure(error));
        } else {
          dispatch(actions.getOldReservationDatesSuccess(data));
        }
      } catch (error) {
        dispatch(actions.getOldReservationDatesFailure(error));
      }
    };
  },
  getReservations: ({ date }) => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.getReservationsRequest());
      try {
        const { error, data } = await getReservations({ identify, date });
        if (error) {
          dispatch(actions.getReservationsFailure(error));
        } else {
          dispatch(actions.getReservationsSuccess(data));
        }
      } catch (error) {
        dispatch(actions.getReservationsFailure(error));
      }
    };
  },
  getReservationProfileById: ({ identify }) => {
    return async (dispatch, getState) => {
      // const localId = getState().account.account.identify;
      dispatch(actions.getReservationProfileRequest());
      try {
        const { error } = await getReservationProfileById({ identify });
        if (error) {
          dispatch(actions.getReservationProfileFailure(error));
        } else {
          dispatch(actions.getReservationProfileSuccess());
          // navigation.navigate('ProfilePaymentScreen', { localId, ...data });
        }
      } catch (error) {
        dispatch(actions.getReservationProfileFailure(error));
      }
    };
  },
  deleteAgreeSchedule: () => {
    return async (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.deleteAgreeScheduleRequest());
      try {
        const { error } = await modify({ identify, price: 0, agreeschedule: '' });
        if (error) {
          dispatch(actions.deleteAgreeScheduleFailure(error));
        } else {
          dispatch(actions.deleteAgreeScheduleSuccess());
          // navigation.navigate('ProfileScreen');
        }
      } catch (error) {
        dispatch(actions.deleteAgreeScheduleFailure(error));
      }
    };
  }
};
