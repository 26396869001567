// REFACTOR/TODO
// Principal libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import Box from './components/Box';

// Extras
import actions from '../../actions/teachers';
import { selectors as accountSelectors } from '../../reducers/account';
import { selectors as teacherSelectors } from '../../reducers/teachers';
import { capitalize } from '../../utils/strings';
import TypeRadio from './components/TypeRadio';
import { fonts } from '../../utils/constants';

const Service = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [item, setItem] = useState(null);
  const [type, setType] = useState('t');
  const { categories, account } = useSelector((state) => ({
    account: accountSelectors.getAccount(state),
    categories: teacherSelectors.getCategories(state)
  }));

  const handleClick = (item) => () => {
    setItem(item);
    setType(item.isHour ? 'h' : item.price === 0 ? 's' : 't');
    setStep(1);
  };

  const handleAdd = () => {
    if (item.isNew) {
      dispatch(
        actions.addCategories({
          category: {
            name: capitalize(item.category.trim()),
            category: capitalize(item.category.trim()),
            description: capitalize(item.description.trim()),
            price: type === 's' ? 0 : item.price,
            isHour: type === 'h'
          }
        })
      );
    }
    setStep(0);
  };

  const handleDelete = () => {
    dispatch(actions.deleteCategory({ category: item.category }));
    setStep(0);
  };

  const handleStep = () => {
    setItem({
      category: '',
      price: 1000,
      description: '',
      isHour: 't',
      isNew: true
    });
    setStep(1);
  };

  useEffect(() => {
    dispatch(actions.getCategories({ identify: account.identify }));
  }, []);

  if (step === 0) {
    return (
      <Container>
        {categories.map((item) => (
          <Box
            key={item.category}
            title={item.category}
            onClick={handleClick(item)}
          />
        ))}
        <Separator />
        <CreateService onClick={handleStep}>+ Nuevo servicio</CreateService>
      </Container>
    );
  }

  return (
    <Content>
      <Title
        placeholder="Escriba aquí el nombre del servicio"
        value={item.category}
        disabled={!item.isNew}
        onChange={({ target }) => setItem({ ...item, category: target.value })}
      />
      <Input
        placeholder="Describa su servicio aquí"
        disabled={!item.isNew}
        value={item.description}
        onChange={({ target }) => setItem({ ...item, description: target.value })}
      />
      <Line higher>
        <Text>Valor del servicio </Text>
        {type !== 's' ? (
          <>
            <Text symbol>$</Text>
            <Price
              type="text"
              disabled={!item.isNew}
              value={item.price}
              onChange={({ target }) => setItem({ ...item, price: target.value })}
            />
          </>
        ) : (
          <Text>a convenir</Text>
        )}
        {type === 'h' && item.price > 0 ? <Text>por hora</Text> : null}
        {!item.isNew ? (
          <DeleteService onClick={handleDelete}>Eliminar servicio</DeleteService>
        ) : null}
      </Line>
      <TypeRadio disabled={!item.isNew} type={type} onChange={setType} />

      <ContractButton onClick={handleAdd}>
        {!item.isNew ? 'Volver' : 'Agregar servicio'}
      </ContractButton>
    </Content>
  );
};

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  min-height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContractButton = styled.button`
  background-color: #1692ff;
  color: white;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 80%;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px 0;
`;

const Title = styled.input`
  width: 85%;
  background-color: #1d233b;
  color: white;
  font-family: ${fonts.principal};
  font-size: 1em;
  padding: 10px;
  resize: none;
  border: 1px solid #bac4f2;
  border-radius: 5px;
`;

const Input = styled.textarea`
  flex: 1;
  width: 85%;
  background-color: #1d233b;
  color: white;
  font-family: ${fonts.principal};
  font-size: 1em;
  padding: 10px;
  resize: none;
  border: 1px solid #bac4f2;
  border-radius: 5px;
  margin: 20px 0;
`;

const Text = styled.p`
  color: #bac4f2;
  margin: 0 5px;
  font-weight: bold;
  margin-left: ${(props) => (props.symbol ? '10px' : '0px')};
`;

const Price = styled.input`
  color: #1692ff;
  font-weight: bold;
  margin: 0px 10px;
  border: none;
  border-bottom: 1px solid #bac4f2;
  padding: 0px 10px;
  background-color: transparent;
  outline: none;
  font-size: 1em;
  width: 100px;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.higher ? 'center' : 'flex-start')};
  align-items: center;
  width: ${(props) => (props.higher ? '100%' : '80%')};
  margin: 20px 5px;
`;

const DeleteService = styled.button`
  outline: none;
  background-color: transparent;
  margin: 20px 0px;
  width: 250px;
  border: none;
  color: #dc4c41;
  font-size: 1em;
  font-weight: bold;
  font-family: ${fonts.principal};
  text-transform: uppercase;
  cursor: pointer;
`;

const Separator = styled.div`
  background-color: #818bb9;
  height: 1px;
  width: 80%;
  margin: 50px auto;
  border-radius: 5px;
`;

const CreateService = styled.button`
  background-color: #333c5d;
  color: #bac4f2;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 250px;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px auto;
`;

export default Service;
