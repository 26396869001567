// Principal libraries
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Reducers
import * as account from '../reducers/account';
import * as external from '../reducers/external';
import * as files from '../reducers/files';
import * as teachers from '../reducers/teachers';
import * as valuations from '../reducers/valuations';

const allReducers = {
  account,
  external,
  files,
  teachers,
  valuations
};

export const useMessage = ({ reducer }) => {
  const [text, setMessage] = useState(null);
  const state = useSelector((st) => st);
  const dispatch = useDispatch();
  let message;
  if (Object.prototype.hasOwnProperty.call(allReducers, reducer)) {
    message = allReducers[reducer].selectors.getMessage(state);
  }
  useEffect(() => {
    if (message) {
      setMessage(message);
      dispatch(allReducers[reducer].actions.reset());
    }
  }, [message]);
  return [text, setMessage];
};

export default useMessage;
