import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { AiOutlineMessage } from 'react-icons/ai';
import Chat from '../../components/chat/Chat';
import { randomNumber } from '../../utils/strings';
import { devicesMax } from '../../utils/constants';
import flvjs from 'flv.js';

const Receptor = () => {
  const [visible, setVisible] = useState(false);
  const [name] = useState(`Anonimo${randomNumber(3)}`);
  const { id } = useParams();
  const videoRef = useRef();

  useEffect(() => {
    if (flvjs.isSupported()) {
      const flvPlayer = flvjs.createPlayer({
        type: 'flv',
        url: `https://streaming.estuap.com/live/${id}.flv`
      });
      flvPlayer.attachMediaElement(videoRef.current);
      flvPlayer.load();
      flvPlayer.on(flvjs.Events.LOADING_COMPLETE, () => {
        flvPlayer.unload();
        flvPlayer.attachMediaElement(videoRef.current);
        flvPlayer.load();
        videoRef.current.play();
      });
      videoRef.current.play();
    } else {
      alert(
        'Tu navegador no soporta esta transmisión, debes utilizar Chrome o Firefox'
      );
    }
  }, [id]);

  const handleVisible = () => setVisible(!visible);

  return (
    <>
      <Content>
        <Video visible={visible} ref={videoRef} controls />
        <ChatContent visible={visible}>
          <Chat idChannel={id} name={name} />
        </ChatContent>
      </Content>
      <Bubble>
        <Icon
          visible={visible}
          onClick={handleVisible}
          color={visible ? '#0e67be' : 'white'}
          size={48}
        />
      </Bubble>
    </>
  );
};

const Content = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 10px 0;
`;

const Icon = styled(AiOutlineMessage)`
  padding: 20px;
  border-radius: 50px;
  background-color: ${(props) => (props.visible ? 'white' : '#0e67be')};
  cursor: pointer;
  transition: all 1s;
  :hover {
    transform: rotate(-380deg);
  }
  @media ${devicesMax.tablet} {
    display: none;
  }
`;

const Bubble = styled.div`
  position: absolute;
  bottom: 200px;
  right: 40px;
  box-sizing: border-box;
  z-index: 1;
`;

const ChatContent = styled.div`
  display: flex;
  width: ${(props) => (props.visible ? '40vw' : '0vw')};
  height: 70vh;
  margin: 1px auto;
  transition: all 1s;
  @media ${devicesMax.tablet} {
    width: 100vw;
    height: 50vh;
    padding: 1px 1px 1px 1px;
  }
  @media ${devicesMax.tablet} and (orientation: landscape) {
    width: 100vw;
    height: 80vh;
    padding: 1px 1px 1px 1px;
  }
`;

const Video = styled.video`
  flex: 1;
  height: 70vh;
  max-width: ${({ visible }) => (visible ? '50%' : '100%')};
  display: block;
  margin: 1px 1px 1px 1px;
  transition: all 1s;
  @media ${devicesMax.tablet} {
    width: 100%;
    margin: 1px 1px 1px 1px;
  }
`;

export default Receptor;
