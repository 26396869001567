// Principal libraries
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

// Components
import InputBox from './components/InputBox';
import PasswordBox from './components/PasswordBox';

// Extras
import actions from '../../../actions/students';
import accountActions from '../../../actions/account';
import { emailValidation } from '../../../utils/validations';
import { fonts, colors } from '../../../utils/constants';
import useMessage from '../../../hooks/useMessage';

const Auth = () => {
  const [message, setMessage] = useMessage({ reducer: 'account' });
  const [reset, setReset] = useState(false);
  const [step, setStep] = useState(0);
  const [mail, setMail] = useState('');
  const [code, setCode] = useState('');
  const [values, setValues] = useState({ username: '', password: '' });
  const dispatch = useDispatch();

  const handleEmailValidation = () => emailValidation(values.username);

  const handleLogin = () => {
    if (handleEmailValidation() && values.password.trim().length > 3) {
      dispatch(
        actions.verifyStudent({
          username: values.username.trim(),
          password: values.password.trim()
        })
      );
    } else {
      setMessage('Algunos datos estan incorrectos');
    }
  };

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleReset = () => {
    setReset(true);
    setStep(0);
    setMail('');
    setCode('');
  };
  const handleChange = (setter) => (evt) => {
    setter(evt.target.value);
  };
  const sendRequestPass = () => {
    if (emailValidation(mail)) {
      dispatch(accountActions.resetPasswordRequest({ mail }));
      setStep(1);
    } else alert('Ingrese una dirección de e-mail válida (su usuario)');
  };
  const sendCode = () => {
    dispatch(accountActions.resetPassword({ mail, code }));
    alert(
      'Se enviará la nueva contraseña a su correo, en caso de que no le llegue contáctenos soporte@estuap.com'
    );
    setTimeout(() => {
      setReset(false);
    });
  };
  return (
    <Container>
      {!reset ? (
        <>
          <InputBox
            title="Usuario"
            placeholder="usuario@estuap.com"
            value={values.username}
            onKeyDown={handleKey}
            onChange={(evt) => setValues({ ...values, username: evt.target.value })}
            maxLength={50}
          />
          <PasswordBox
            title="Contraseña"
            placeholder="*****"
            value={values.password}
            onKeyDown={handleKey}
            onChange={(evt) => setValues({ ...values, password: evt.target.value })}
            maxLength={30}
          />
          {message ? <Error>{message}</Error> : null}
          <Button custom success onClick={handleLogin}>
            Ingresar
          </Button>
          <Button onClick={handleReset}>Olvidé mi contraseña</Button>
        </>
      ) : (
        <>
          {step === 0 && (
            <>
              <Text>
                Ingrese su E-mail con el cual se registró en la plataforma.
                <br />
                Luego de esto le llegará un código a su e-mail para verificar que sea
                de usted (no olvide chequear la bandeja de spam).
              </Text>
              <InputBox
                title="Usuario"
                placeholder="email@email.com"
                value={mail}
                onChange={handleChange(setMail)}
                maxLength={50}
              />
              <Button success onClick={sendRequestPass}>
                Solicitar código
              </Button>
            </>
          )}
          {step === 1 && (
            <>
              <Text>Ingrese el código que le enviamos a {mail}</Text>
              <InputBox
                title="Código"
                placeholder="CÓDIGO"
                value={code}
                onChange={handleChange(setCode)}
                maxLength={6}
              />
              <Button success onClick={sendCode}>
                Verificar
              </Button>
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Button = styled.a`
  cursor: pointer;
  color: white;
  background-color: ${(props) => (props.success ? '#0E67BE' : 'gray')};
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  margin-top: ${(props) => (props.success ? '60px' : '10px')};
  margin-bottom: 20px;
  font-weight: bold;
  width: ${(props) => (props.custom ? '40%' : 'auto')};
`;
const Text = styled.p`
  font-family: ${fonts.principal};
  color: ${colors.black};
  font-size: 1em;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
`;
const Error = styled.p`
  text-align: center;
  font-family: ${fonts.principalBold};
  color: ${colors.red};
  font-size: 14px;
  margin: 4px 0px 4px 10px;
`;

export default Auth;
