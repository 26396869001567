import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../utils/constants';

const Fetching = ({ show }) =>
  show ? (
    <Container>
      <Box>
        <Spin />
      </Box>
    </Container>
  ) : null;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
`;

const Box = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.black};
  border-radius: 10px;
  opacity: 0.5;
  z-index: 1;
`;

const spin_safari = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spin = styled.div`
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: ${spin_safari} 0.8s linear infinite;
  animation: ${spin} 0.8s linear infinite;
`;

export default Fetching;
