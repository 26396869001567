import React from 'react';
import styled from 'styled-components';

const ToolTip = ({ visible, text }) => {
  return <Tip visible={visible}>{text}</Tip>;
};

const Tip = styled.span`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  transition:all 1s;
`;

export default ToolTip;
