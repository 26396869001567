// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { FaChevronCircleDown } from 'react-icons/fa';

// Extras
import { capitalize } from '../../../../../utils/strings';

const Box = ({ title, onClick }) => (
  <Container onClick={onClick}>
    <Text>{capitalize(title)}</Text>
    <FaChevronCircleDown color="white" size={32} />
  </Container>
);

const Container = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 5px auto;
  width: 85%;
  background-color: #333c5d;
  border-radius: 5px;
  cursor: pointer;
`;

const Text = styled.p`
  flex: 1;
  color: #bac4f2;
  margin: 0px 5px;
  font-weight: bold;
`;

export default Box;
