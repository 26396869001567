// Principal libraries
import React from 'react';
import styled, { keyframes } from 'styled-components';

const Modal = (props) => {
  if (props.open) {
    return (
      <Container zIndex={props.zIndex} fetch={props.fetch}>
        <Content
          fullScreen={props.fullScreen}
          minHeight={props.minHeight}
          fullScreenOnMobile={props.fullScreenOnMobile}
          width={props.width}
          border={props.border}
          background={props.background}
        >
          <Header subTitle={props.subTitle}>
            <Title>{props.title}</Title>
            <Close onClick={props.onClick}>X</Close>
          </Header>
          {props.children}
        </Content>
      </Container>
    );
  }
  return null;
};

const Content = styled.div`
  position: fixed;
  background: ${(props) => (props.background ? props.background : 'whitesmoke')};
  width: ${(props) =>
    props.fullScreen ? '100%' : props.width ? props.width : '60%'};
  height: ${(props) => (props.fullScreen ? '100%' : 'auto')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: ${(props) => (props.border ? props.border : '5px')};
  @media (max-width: 1100px) {
    width: ${(props) =>
      props.fullScreen || props.fullScreenOnMobile ? '100%' : '90%'};
    height: ${(props) => (props.fullScreenOnMobile ? '100%' : 'auto')};
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const fade = keyframes`
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
`;

const Container = styled.div`
  animation: ${fade} 0.3s ease-in-out;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '1')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  filter: ${(props) => (props.fetch ? 'blur(2px)' : 'none')};
  -webkit-filter: ${(props) => (props.fetch ? 'blur(2px)' : 'none')};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: ${(props) => (props.subTitle ? 'left' : 'center')};
  font-size: ${(props) => (props.subTitle ? '0.8em' : '1.2em')};
  color: ${(props) => (props.subTitle ? '#bac4f2' : '#0E67BE')};
  padding: 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled.p`
  flex: 1;
  font-weight: bold;
  margin: 0;
  color: #1692ff;
`;

const Close = styled.a`
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2em;
  color: black;
`;

export default Modal;
