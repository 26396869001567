import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import styled from 'styled-components';
import BoxMessage from './components/BoxMessage';
import { randomColor } from '../../utils/colors';
import { MdSearch } from 'react-icons/md';
import actions from '../../actions/teachers';
import { selectors } from '../../reducers/account';
import { CHAT } from '../../middleware/constants';
import { fonts } from '../../utils/constants';

let socket;

const Chat = ({ idChannel, name, fullScreen = false }) => {
  const chats = useSelector(selectors.getChats);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [color] = useState(randomColor());
  const dispatch = useDispatch();

  const onSend = (evt) => {
    evt.preventDefault();
    if (value.trim()) {
      const date = new Date();
      socket.emit('stream:chat:message', {
        message: value,
        idChannel,
        color,
        name,
        date
      });
      dispatch(
        actions.sendMessage({
          message: value,
          idChannel,
          color,
          name,
          date
        })
      );
      setValue('');
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSend(event);
    }
  };

  useEffect(() => {
    socket = io.connect(CHAT, { transports: ['websocket'] });
    socket.emit('stream:chat:join', idChannel);
    socket.on('stream:chat:message', (data) => {
      dispatch(actions.sendMessage(data));
    });
    return () => {
      socket.emit('stream:chat:disconnect', idChannel);
      socket.close();
    };
  }, []);

  return (
    <Content fullScreen={fullScreen}>
      <Container>
        {chats &&
          chats[idChannel] &&
          chats[idChannel]
            .filter((item) =>
              item.message.toLowerCase().includes(search.toLocaleLowerCase())
            )
            .map((message, i) => (
              <BoxMessage
                key={`${message.name} ${message.date} ${message.message}`}
                message={message}
              />
            ))}
      </Container>
      <Bottom>
        <Row onSubmit={onSend}>
          <Input
            rows={3}
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            onKeyDown={onKeyDown}
            placeholder="Escriba aquí..."
          />
        </Row>
        <BottomContent>
          <MdSearch size={30} color="white" />
          <Search
            type="text"
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            placeholder="Busca mensajes"
          />
        </BottomContent>
      </Bottom>
    </Content>
  );
};

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 10px auto;
  border: 1px solid #0e67be;
  border-radius: 5px;
  background-color: #0e67be;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #0e67be;
  border-radius: 5px;
`;

const Search = styled.input`
  flex: 1;
  color: white;
  outline: none;
  border: none;
  background-color: transparent;
  ::placeholder {
    font-family: ${fonts.principal};
    color: white;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 1s;
  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div`
  padding: 10px 0px;
  border: 2px solid #0e67be;
  flex: 1;
  min-height: 20vh;
  width: 95%;
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
  background-color: #f7f7f7;
  margin: 0px auto;
  overflow: scroll;
`;

const Row = styled.form`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid #0e67be;
  background: white;
`;

const Input = styled.textarea`
  flex: 1;
  text-align: left;
  outline: none;
  border: none;
  background-color: transparent;
  color: black;
  padding: 5px;
  font-family: ${fonts.principal};
  font-size: 1em;
  resize: none;
  ::placeholder {
    font-family: ${fonts.principal};
    color: gray;
  }
`;

export default Chat;
