// Principal libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import Header from './components/Header';
import InputBox from '../../components/InputBox';

// Extras
import { selectors } from '../../reducers/account';
import { colors, fonts, devices } from '../../utils/constants';

const Profile = () => {
  const account = useSelector(selectors.getAccount);
  const [name, setName] = useState(account.name);
  return (
    <>
      <Title>Tu perfil</Title>
      <Content>
        <Header
          name={`${name} ${account.surname}`}
          rate={account.rate}
          rateTeacher={account.rate}
          photo={account.photo}
        />
        <Column>
          <InputBox title="Nombre" value={name} onChange={setName} />
          <InputBox type="date" title="Fecha de nacimiento" />
          <InputBox type="textarea" title="Sobre mí" />
          <InputBox type="textarea" title="¿Qué enseño?" />
          <Line />
          <BackgroundButton to="/services">Mis horarios</BackgroundButton>
          <SimpleButton>Vista previa</SimpleButton>
        </Column>
      </Content>
    </>
  );
};

const Content = styled.div`
  padding: 20px 20px 20px 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media ${devices.laptop} {
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Line = styled.div`
  background-color: #818bb9;
  height: 1px;
  width: 80%;
  margin: ${(props) => (props.margin ? props.margin : '10px')} auto;
  border-radius: 5px;
`;

const Title = styled.h2`
  margin-top: 40px;
  margin-left: 40px;
  color: ${colors.black};
  font-family: ${fonts.muli};
`;

const Button = styled(Link)`
  margin: 10px 10px 10px 10px;
  text-align: center;
`;

const BackgroundButton = styled(Button)`
  background-color: ${colors.skyBlue};
  border-radius: 20px;
  font-weight: bold;
  padding: 15px 50px;
  color: ${colors.white};
  font-size: 0.9em;
  box-shadow: 3px 3px 6px 1px ${colors.darkgray};
`;

const SimpleButton = styled(BackgroundButton)`
  background-color: ${colors.white};
  color: ${colors.skyBlue};
  font-weight: bold;
`;

export default Profile;
