export const types = {}; // SUSTITUIDOS POR STUDENS_ACCOUNT EN REDUCERS/ACCOUNT.JS

export const INITIAL_STATE = {}; // Sustituido por studentAccount en INITIAL_STATE en reducers/account.js

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  switch (action.type) {
    default:
      return state;
  }
};

export const actions = {}; // ENVIADO A STUDENT_ACTIONS EN ACCOUNT.JS

export const selectors = {}; // STUDENTS SELECTORS
