import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FadeInLeft, ZoomIn } from '../Keyframes';

const Tutorial = () => {
  const registerRef = useRef();
  const loginRef = useRef();
  const [blur, setBlur] = useState({ register: true, login: true });

  const handleRegister = () => {
    setBlur({ ...blur, register: false });
  };

  const handleLogin = () => {
    setBlur({ ...blur, login: false });
  };

  return (
    <Container id='tutorials'>
      <Content>

          <Title>También podrás Filtrar las búsquedas por distancia precio o calificación. 
                Todos los docentes y alumnos están valorados y calificados para que el servicio sea siempre el mejor.
                Fácil.
          </Title>
          <Image src="assets/images/ESTUAP.png" />
          <Image src="assets/images/ESTUAP1.png" /> 
      </Content>
      <BoxDowload> <Download href="https://www.estuap.com/descarga" target="_blank"> Descarga la app</Download></BoxDowload>
    </Container>
  );
};

const Image = styled.img`
  margin-top:0px;
  margin-bottom: 5px;
  width: 28%;
  animation: ${ZoomIn} 3s;
  @media (max-width:813px){
    margin-top: 40px;
    width: 50%;
  }
`;
const BoxDowload = styled.div`
  @media (max-width:813px){
    width:100%;
    display:flex;
    justify-content:center;
  }
`;

const Download = styled.a`
  width: 200px;
  background: none;
  border: solid 2px #0082f7;
  justify-content:center;
  align-items: center;
  font-family: 'Muli';
  font-size: 1em;
  cursor: pointer;
  color: #0082f7;
  border-radius: 5px;
  height: 45px;
  display: flex;
  margin-top: -240px;
  margin-left: 60px;
  transition: all 0.5s;
  animation: ${FadeInLeft} 4s;
  :hover{
    background: #0082f7;
    color: white;
  }
  @media (max-width:813px){
    margin: 0px;
  }

`;


const Container = styled.div`
  text-align: center;
  flex-direction: column;
  background: white;
  padding: 50px 0;
  height:50%;
  @media (max-width:813px){
    height: auto;
    padding: 0 0 50px 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  @media (max-width:813px){
    flex-wrap: wrap;
  }
`;

const Title = styled.h2`
  color: black;
  font-family: 'Muli';
  width: 90%;
  text-align: left;
  letter-spacing: 0.5px;
  font-size: 17px;
  margin: 30px auto;
  margin-left: 40px;
  animation: ${FadeInLeft} 3s;
  @media (max-width:813px){
    margin: 0px;
    padding: 10px;
    text-align: center;
  }
`;

const Box = styled.div`
  text-align: center;
  width: 20%;
`;

const Video = styled.video`
  width: 90%;
  outline: none;
  margin: 5px;
  filter: ${({ blur }) => (blur ? 'blur(4px)' : 'blur(0px)')};
  object-fit: cover;
  animation: ${ZoomIn} 3s;
`;

export default Tutorial;
