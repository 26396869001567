/* eslint-disable no-plusplus */
/**
 * This file contain handle array functions
 * For example: remove an element on array
 */

/**
 * "[1, 2, 3] => [1, 2]"
 * @param {array} array []
 * @param {string} elem Text
 */
export const removeElement = (array, elem) => {
  const index = array.indexOf(elem);
  if (index > -1) {
    array.splice(index, 1);
  }
};

/**
 * "[{ name: 'Jose' }, { name: 'Pepe' }] => [{ name: 'Jose' }]"
 * @param {array} array []
 * @param {string} attr Attribute
 * @param {string} elem Text
 */
export const removeObject = (array, attr, elem) => {
  for (let i = 0; i < array.length; i++)
    if (array[i][attr] === elem) {
      array.splice(i, 1);
      break;
    }
};

/**
 * "[1, 2, 3] => true"
 * @param {array} array []
 * @param {string} elem Text
 * @return {boolean} true/false
 */
export const hasElem = (array, elem) => {
  if (array.indexOf(elem) > -1) {
    return true;
  }
  return false;
};

/**
 * "[{ name: 'Jose' }, { name: 'Pepe' }] => true"
 * @param {array} array []
 * @param {string} elem Text
 * @return {boolean} true/false
 */
export const hasElemOnObject = (array, attr, elem) => {
  for (let i = 0; i < array.length; i++) if (array[i][attr] === elem) return true;
  return false;
};
