// Principal libraries
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEye } from 'react-icons/fa';

const PasswordBox = (props) => {
  const [viewPass, setViewPass] = useState(false);

  const handleViewPass = () => {
    setViewPass(!viewPass);
  };

  return (
    <Container>
      <Label>{props.title}:</Label>
      <Row>
        <Input
          {...props}
          type={viewPass ? 'text' : 'password'}
          autocorrect="off"
          autocapitalize="none"
        />
        <Eye size={24} color="black" onClick={handleViewPass} active={viewPass} />
      </Row>
    </Container>
  );
};

const Container = styled.div`
  margin: 10px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  text-align: left;
  width: 100px;
  margin: 2px;
  font-weight: bold;
  color: #0e67be;
`;

const Input = styled.input`
  flex: 1;
  outline: none;
  font-size: 1em;
  font-family: 'Helvetica';
  padding: 5px 20px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid gray;
  background-color: transparent;
  color: black;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
`;

const Eye = styled(FaEye)`
  position: absolute;
  right: 12px;
  cursor: pointer;
  transition: all 1s;
  opacity: ${({ active }) => (!active ? '0.5' : '1')};
`;

export default PasswordBox;
