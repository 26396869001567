export const types = {
  FILES_CLEAN_MESSAGE: 'files/FILES_CLEAN_MESSAGE',
  FILES_UPLOAD_REQUEST: 'files/FILES_UPLOAD_REQUEST',
  FILES_UPLOAD_FAILURE: 'files/FILES_UPLOAD_FAILURE',
  FILES_UPLOAD_SUCCESS: 'files/FILES_UPLOAD_SUCCESS'
};

export const INITIAL_STATE = {
  isFetching: false,
  message: null,
  url: null
};

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  switch (action.type) {
    case types.FILES_CLEAN_MESSAGE:
      return { ...state, message: null };
    case types.FILES_UPLOAD_REQUEST:
      return { ...state, isFetching: true };
    case types.FILES_UPLOAD_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.FILES_UPLOAD_SUCCESS:
      return { ...state, isFetching: false, url: action.url };
    default:
      return state;
  }
};

export const actions = {
  uploadRequest: () => ({
    type: types.FILES_UPLOAD_REQUEST
  }),
  uploadFailure: (message) => ({
    type: types.FILES_UPLOAD_FAILURE,
    message
  }),
  uploadSuccess: (url) => ({
    type: types.FILES_UPLOAD_SUCCESS,
    url
  }),
  reset: () => ({
    type: types.EXTERNAL_CLEAN_MESSAGE
  })
};

export const selectors = {
  isFetching: ({ files }) => files.isFetching,
  getMessage: ({ files }) => files.message,
  getUrl: ({ files }) => files.url
};
