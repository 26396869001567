// Principal libraries

// Actions
import { actions } from '../reducers/account';

// Middleware
import {
  modify as modifyAccountTeacher,
  getInfo as getAccountTeacherInfo,
  createLanguage,
  getTeacherInfo
} from '../middleware/teachers';
import {
  updateSession,
  logout,
  resetPasswordRequest,
  resetPassword,
  registerToken,
  deleteAccount
} from '../middleware/session';
import { reportProblem } from '../middleware/reports';

// Extras
import { LINKED_ACCOUNTS_NUMBERS, SECRET_WORD } from '../utils/constants';

const updateInfoLanguage = ({ languages }, someLanguage) => {
  const newLanguages = [];
  if (someLanguage.level !== -1) newLanguages.push(someLanguage);
  languages.map((v) => {
    if (v.language !== someLanguage.language && v.level !== -1) {
      newLanguages.push(v);
    }
    return v;
  });
  return newLanguages;
};

const UPDATE_METHODS = {
  updateInfoRequest: (params) => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      const { info } = getState().account;
      dispatch(actions.updateInfoRequest());
      modifyAccountTeacher({ identify, ...params })
        .then((result) => {
          if (result.error) {
            dispatch(actions.updateInfoFailure(result.error));
          } else {
            dispatch(
              actions.updateInfoSuccess(JSON.stringify(result), {
                ...info,
                ...params
              })
            );
          }
        })
        .catch((error) => dispatch(actions.updateInfoFailure(error)));
    };
  },
  updateLanguageRequest: (params) => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      const { info } = getState().account;
      const languages = updateInfoLanguage(info, params);
      dispatch(actions.updateInfoRequest());
      createLanguage({ identify, ...params })
        .then((result) => {
          if (result.error) {
            dispatch(actions.updateInfoFailure(result.error));
          } else {
            dispatch(
              actions.updateInfoSuccess(JSON.stringify(result), {
                ...info,
                languages
              })
            );
          }
        })
        .catch((error) => dispatch(actions.updateInfoFailure(error)));
    };
  },
  logout: () => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      logout({ identify })
        .catch((err) => console.error(err))
        .finally(() => {
          dispatch(actions.delToken());
          dispatch(actions.logged(false));
        });
    };
  },
  deleteAccount: (problem) => {
    return async (dispatch, getState) => {
      try {
        const {
          account: { identify }
        } = getState().account;
        dispatch(actions.deleteAccountRequest());
        const { error } = await deleteAccount({
          identify,
          problem: `id: ${identify} - Dió de baja la cuenta: ${problem}`
        });
        if (error) {
          dispatch(actions.deleteAccountFailure(error));
        } else {
          dispatch(actions.deleteAccountSuccess());
          // navigation.navigate('HomeScreen');
        }
      } catch (ex) {
        dispatch(actions.deleteAccountFailure(ex));
      }
    };
  }
};

export default {
  reportProblemRequest: (params) => {
    return async (dispatch) => {
      dispatch(actions.reportProblemRequest());
      try {
        await reportProblem({ ...params });
        dispatch(actions.reportProblemSuccess(null));
        // navigation.navigate('ReportProblemSuccess');
      } catch (err) {
        dispatch(
          actions.reportProblemFailure({
            error: true,
            text: JSON.stringify(err)
          })
        );
      }
    };
  },
  infoRequest: () => {
    return (dispatch, getState) => {
      const {
        account: { identify }
      } = getState().account;
      dispatch(actions.infoRequest());
      getAccountTeacherInfo({ identify })
        .then((response) => {
          dispatch(actions.infoSuccess(response.data));
        })
        .catch((error) => dispatch(actions.infoFailure(error)));
    };
  },
  getInfo: (params) => {
    return (dispatch) => {
      dispatch(actions.infoRequest());
      getTeacherInfo(params)
        .then((response) => {
          dispatch(actions.infoSuccess(response.data));
        })
        .catch((error) => dispatch(actions.infoFailure(error)));
    };
  },
  resetPasswordRequest: (params) => {
    return (dispatch) => {
      dispatch(actions.resetPasswordRequest());
      resetPasswordRequest(params)
        .then(({ error }) => {
          if (error) {
            dispatch(actions.resetPasswordFailure(error));
          } else {
            dispatch(actions.resetPasswordSuccess());
          }
        })
        .catch((error) => dispatch(actions.resetPasswordFailure(error)));
    };
  },
  resetPassword: (params) => {
    return (dispatch) => {
      dispatch(actions.resetPasswordRequest());
      resetPassword(params)
        .then(({ error }) => {
          if (error) {
            dispatch(actions.resetPasswordFailure(error));
          } else {
            dispatch(actions.resetPasswordSuccess());
          }
        })
        .catch((error) => dispatch(actions.resetPasswordFailure(error)));
    };
  },
  requestRegisterToken: () => {
    return (dispatch) => {
      dispatch(actions.infoRequest());
      registerToken({ secretword: SECRET_WORD }).then((response) => {
        dispatch(actions.setRegisterToken(response.data));
      });
    };
  },
  ...UPDATE_METHODS
};
