const FETCH_GOOGLE_API = {
  EXTERNAL_ADDRESS_REQUEST: 'external/EXTERNAL_ADDRESS_REQUEST',
  EXTERNAL_ADDRESS_SUCCESS: 'external/EXTERNAL_ADDRESS_SUCCESS',
  EXTERNAL_ADDRESS_FAILURE: 'external/EXTERNAL_ADDRESS_FAILURE',
  EXTERNAL_DEPARTAMENT_REQUEST: 'external/EXTERNAL_DEPARTAMENT_REQUEST',
  EXTERNAL_DEPARTAMENT_SUCCESS: 'external/EXTERNAL_DEPARTAMENT_SUCCESS',
  EXTERNAL_DEPARTAMENT_FAILURE: 'external/EXTERNAL_DEPARTAMENT_FAILURE'
};

export const types = {
  ...FETCH_GOOGLE_API,
  EXTERNAL_CLEAN_MESSAGE: 'external/EXTERNAL_CLEAN_MESSAGE'
};

const INITIAL_FETCH_GOOGLE_API = {
  currentPlace: {
    country: 'Uruguay',
    locality: 'Montevideo',
    province: 'Montevideo'
  },
  location: null,
  isFetching: false,
  message: ''
};

export const INITIAL_STATE = {
  ...INITIAL_FETCH_GOOGLE_API
};

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  const { location, currentPlace } = action;
  switch (action.type) {
    case types.EXTERNAL_CLEAN_MESSAGE:
      return { ...state, message: null };
    case types.EXTERNAL_ADDRESS_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.EXTERNAL_ADDRESS_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.EXTERNAL_ADDRESS_SUCCESS:
      return { ...state, isFetching: false, location };
    case types.EXTERNAL_DEPARTAMENT_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.EXTERNAL_DEPARTAMENT_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.EXTERNAL_DEPARTAMENT_SUCCESS:
      return { ...state, isFetching: false, currentPlace };
    default:
      return state;
  }
};

const FETCH_GOOGLE_API_ACTIONS = {
  getLocationAddressRequest: () => ({
    type: types.EXTERNAL_ADDRESS_REQUEST
  }),
  getLocationAddressFailure: (message) => ({
    type: types.EXTERNAL_ADDRESS_FAILURE,
    message
  }),
  getLocationAddressSuccess: (message, location) => ({
    type: types.EXTERNAL_ADDRESS_SUCCESS,
    message,
    location
  }),
  getCurrentPlaceRequest: () => ({
    type: types.EXTERNAL_DEPARTAMENT_REQUEST
  }),
  getCurrentPlaceFailure: (message) => ({
    type: types.EXTERNAL_DEPARTAMENT_FAILURE,
    message
  }),
  getCurrentPlaceSuccess: (message, currentPlace) => ({
    type: types.EXTERNAL_DEPARTAMENT_SUCCESS,
    message,
    currentPlace
  }),
  reset: () => ({
    type: types.EXTERNAL_CLEAN_MESSAGE
  })
};

export const actions = {
  ...FETCH_GOOGLE_API_ACTIONS
};

const FETCH_GOOGLE_API_SELECTORS = {
  getLocation: ({ external }) => external.location,
  isFetching: ({ external }) => external.isFetching,
  getMessage: ({ external }) => external.message,
  getCurrentPlace: ({ external }) => external.currentPlace,
  getCurrentDepartament: ({ external }) => external.currentPlace.departament,
  getCurrentCountry: ({ external }) => external.currentPlace.country
};

export const selectors = {
  ...FETCH_GOOGLE_API_SELECTORS
};
