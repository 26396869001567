// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../../utils/constants';

const BoxMessage = ({ message }) => {
  return (
    <Container>
      <Row>
        <Name color={message.color}>{message.name}: </Name>
        <Text>{message.message}</Text>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.sender ? 'row-reverse' : 'row')};
  align-items: end;
`;

const Text = styled.p`
  flex: 1;
  text-align: left;
  color: black;
  max-width: 300px;
  overflow-wrap: break-word;
  box-sizing: border-box;
  margin: 5px 5px 5px 0;
`;

const Name = styled.p`
  text-align: left;
  color: ${(props) => props.color};
  max-width: 200px;
  overflow-wrap: break-word;
  font-weight: bold;
  font-family: ${fonts.principal};
  font-size: 0.9;
  box-sizing: border-box;
  margin: 5px 5px 5px 10px;
`;

export default BoxMessage;
