// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { FaChevronCircleDown } from 'react-icons/fa';

// Extras
import { capitalize } from '../../../../../utils/strings';
import { fonts } from '../../../../../utils/constants';

const Box = ({ title, description = '', onClick, on }) => (
  <Content onClick={onClick} on={on}>
    <Container>
      <Text>{capitalize(title)}</Text>
      <FaChevronCircleDown color="white" size={32} />
    </Container>
    <Input on={on} row={3} disabled value={description} />
  </Content>
);

const Content = styled.a`
  margin: 5px auto;
  width: 85%;
  border-radius: 5px;
  cursor: pointer;
  height: ${(props) => (props.on ? '200px' : '55px')};
  background-color: ${(props) => (props.on ? '#1D233B' : '#333c5d')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
`;

const Container = styled.div`
  display: inline-block;
  padding: 0px 10px;
  background-color: #0e67be;
`;

const Text = styled.p`
  color: white;
  margin: 0px 5px;
  padding: 20px 5px;
  font-weight: bold;
  display: inline-block;
  vertical-align: super;
  width: 86%;
`;

const Input = styled.textarea`
  resize: none;
  background-color: transparent;
  color: white;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  padding: 20px;
  outline: none;
  border: none;
`;

export default Box;
