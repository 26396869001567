import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FaEye } from 'react-icons/fa';
import { AiOutlineReload } from 'react-icons/ai';
import { Link as RouteTo, NavLink as RouteNav, useLocation } from 'react-router-dom';

// Extras
import actions from '../../actions/account';
import { selectors } from '../../reducers/account';

const Menu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isStart = location.pathname === '/stream';
  const { account, isLogged } = useSelector((state) => ({
    isLogged: selectors.isLogged(state),
    account: selectors.getAccount(state)
  }));

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  const handleReconnect = () => {
    window.location.href = window.location.href;
  };

  if (location.pathname === '/' && !isLogged) {
    return null;
  }

  if (isLogged) {
    return (
      <>
        <Container>
          <Content>
            <Option>
              <Link to="/">Inicio</Link>
            </Option>
            <Right>
              {account.isTeacher && !location.pathname.includes('online') ? (
                <Option>
                  <NavLink to={isStart ? '/' : '/stream'}>
                    <Class isStart={isStart}>
                      {isStart ? (
                        <Text>Finalizar clase</Text>
                      ) : (
                        <Text>Iniciar clase online</Text>
                      )}
                      <FaEye size={24} color="white" />
                    </Class>
                  </NavLink>
                </Option>
              ) : (
                <Option>
                  <Link onClick={handleReconnect}>
                    <Class isStart={false}>
                      <Text>Recargar clase</Text>
                      <AiOutlineReload size={24} color="white" />
                    </Class>
                  </Link>
                </Option>
              )}
              <Option>
                <NavLink to="/" onClick={handleLogout} reverse>
                  Cerrar sesión
                </NavLink>
              </Option>
            </Right>
          </Content>
        </Container>
        <Spacing />
      </>
    );
  }

  return (
    <>
      <Container isContent>
        <Content>
          <Option>
            <Link to="/">Inicio</Link>
          </Option>
          <Right>
            {location.pathname.includes('online') && (
              <Option>
                <Link onClick={handleReconnect}>
                  <Class isStart={false}>
                    <Text>Recargar clase</Text>
                    <AiOutlineReload size={24} color="white" />
                  </Class>
                </Link>
              </Option>
            )}
          </Right>
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: #1b3764;
  width: 100%;
  position: ${(props) => (props.isContent ? 'initial' : 'fixed')};
  z-index: 2;
`;

const Content = styled.ul`
  padding: 0;
  display: inline-block;
  width: 100%;
  margin: 0;
  text-align: left;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const Right = styled.div`
  float: right;
  @media (max-width: 490px) {
    float: initial;
    text-align: center;
  }
`;

const Class = styled.div`
  background-color: ${(props) => (props.isStart ? '#DE0000' : '#F79919')};
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
`;

const Text = styled.p`
  display: inline-block;
  margin: 0px 10px;
  font-family: 'Muli';
`;

const Option = styled.li`
  display: inline-block;
  padding: 20px;
`;

const Link = styled(RouteTo)`
  color: ${(props) => (props.reverse ? '#1692FF' : 'white')};
  font-family: 'Muli';
  font-size: 1em;
`;

const NavLink = styled(RouteNav)`
  color: ${(props) => (props.reverse ? '#1692FF' : 'white')};
  font-family: 'Muli';
  font-size: 1em;
`;

const Spacing = styled.div`
  height: 65px;
  width: 100%;
`;

export default Menu;
