/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/**
 * This file contain handle string functions
 * For example: generate a random string
 */

/**
 * "hello => Hello"
 * @param {string} text Text
 * @return {string} Text
 */
export const capitalize = (text) => {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

/**
 * "{ name: 'John' surname: ' Hows '} => { name: 'John', surname: 'Hows' }"
 * @param {object} object { key: ' ' }
 * @return {object} { key: '' }
 */
export const trimObject = (object) => {
  if (!object && typeof object !== 'object') return;
  Object.keys(object).map(
    (k) => (object[k] = typeof object[k] === 'string' ? object[k].trim() : object[k])
  );
};

/**
 * "8 => 'jFO1FeuD'"
 * @param {number} length number
 * @return {string} '********'
 */
export const randomString = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * "8 => 'jFO1FeuD'"
 * @param {number} length number
 * @return {string} '********'
 */
export const randomCode = (length) => {
  let result = '';
  const characters =
    '0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * "10, Hour => Hours"
 * @param {number} number Quantity
 * @param {string} text Text
 * @return {string} (number) Text/s
 */
export const toPlural = (quantity, singularText) => {
  if (quantity > 1) return `${quantity} ${singularText}s`;
  return `${quantity} ${singularText}`;
};


export const randomNumber = (length) => {
  let result = '';
  const characters =
    '123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};