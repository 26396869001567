/* eslint-disable no-param-reassign */
import { objectToDate } from '../utils/dates';

// Actions
import { actions } from '../reducers/account';
// Middleware
import {
  setStudents,
  modify,
  get,
  modifyPassword,
  verify
} from '../middleware/students';
import { verifyUsername, login } from '../middleware/session';
import { LINKED_ACCOUNTS_NUMBERS } from '../utils/constants';

export default {
  AddStudents: (params) => {
    return (dispatch) => {
      dispatch(actions.saveStudentsRequest());
      setStudents(params)
        .then(({ error, data }) => {
          if (error) {
            dispatch(actions.saveStudentsFailure(error));
          } else {
            const birthdate = objectToDate(params.date);
            delete params.card;
            delete params.date;
            delete params.isLogin;
            delete params.repeatPass;
            const { identify, token } = data;
            dispatch(actions.setToken(token));
            dispatch(
              actions.saveStudentsSuccess({
                ...params,
                identify,
                birthdate,
                isTeacher: false,
                rate: 5
              })
            );
            dispatch(actions.logged(true));
          }
        })
        .catch((error) => dispatch(actions.saveStudentsFailure(error)));
    };
  },
  getStudent: (params) => {
    return (dispatch) => {
      dispatch(actions.saveStudentsRequest());
      verifyUsername({ username: params.username, socialId: params.socialId })
        .then(({ error, data }) => {
          if (error) {
            dispatch(actions.saveStudentsFailure(error));
          } else if (data && data.length) {
            dispatch(actions.setToken(data[0].token));
            dispatch(actions.saveStudentsSuccess(...data));
            dispatch(actions.logged(true));
            // navigation.navigate('Main');
          } else {
            // navigation.navigate(params.to, params);
            dispatch(actions.saveStudentsSuccess(null));
          }
          dispatch(
            actions.setLinkedAccount(
              params.linkedAccount
                ? params.linkedAccount
                : LINKED_ACCOUNTS_NUMBERS.NONE
            )
          );
        })
        .catch((error) => dispatch(actions.saveStudentsFailure(error)));
    };
  },
  verifyUsername: (params) => {
    return (dispatch) => {
      dispatch(actions.verifyUsernameRequest());
      verify({ username: params.username })
        .then(({ error, data }) => {
          if (error) {
            dispatch(
              actions.verifyUsernameFailure({
                text: error,
                error: true
              })
            );
          } else if (data && data.length) {
            dispatch(
              actions.verifyUsernameFailure({
                text: 'El correo ya existe ',
                error: true
              })
            );
          } else {
            dispatch(actions.verifyUsernameSuccess());
            // navigation.navigate('Step3', params);
          }
        })
        .catch((error) =>
          dispatch(
            actions.verifyUsernameFailure({
              text: error,
              error: true
            })
          )
        );
    };
  },
  getStudentByIdentify: () => {
    return (dispatch, getState) => {
      dispatch(actions.saveStudentsRequest());
      const {
        account: { identify }
      } = getState().account;
      get({ identify })
        .then(({ error, data }) => {
          if (error) {
            dispatch(actions.saveStudentsFailure(error));
          } else if (data && data.length) {
            dispatch(actions.saveStudentsSuccess(...data));
          }
        })
        .catch((error) => dispatch(actions.saveStudentsFailure(error)));
    };
  },
  verifyStudent: (params) => {
    return (dispatch) => {
      dispatch(actions.saveStudentsRequest());
      login(params)
        .then(({ error, data }) => {
          if (error) {
            dispatch(actions.saveStudentsFailure(error));
          } else if (data && data.length) {
            dispatch(actions.setToken(data[0].token));
            dispatch(actions.saveStudentsSuccess(...data));
            dispatch(actions.logged(true));
            dispatch(actions.showLogin(false));
          } else {
            dispatch(
              actions.saveStudentsFailure('Usuario y/o contraseña incorrecto')
            );
          }
        })
        .catch(() => dispatch(actions.saveStudentsFailure('Ha ocurrido algún error en el servidor, vuelva más tarde')));
    };
  },
  modifyStudent: (params) => {
    return (dispatch, getState) => {
      const { account } = getState().account;
      dispatch(actions.saveStudentsRequest());
      modify({ identify: account.identify, ...params })
        .then(({ error }) => {
          if (error) {
            dispatch(actions.saveStudentsFailure(error));
          } else {
            dispatch(actions.saveStudentsSuccess({ ...account, ...params }));
            // navigation.pop();
          }
        })
        .catch((error) => dispatch(actions.saveStudentsFailure(error)));
    };
  },
  modifyPassword: (params) => {
    return (dispatch, getState) => {
      const { account } = getState().account;
      dispatch(actions.saveStudentsRequest());
      modifyPassword({ identify: account.identify, ...params })
        .then(({ error }) => {
          if (error) {
            dispatch(actions.saveStudentsFailure(error));
          } else {
            dispatch(actions.saveStudentsSuccess({ ...account }));
            // navigation.pop();
          }
        })
        .catch((error) => dispatch(actions.saveStudentsFailure(error)));
    };
  }
};
