// Principal libraries
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import AsyncStorage from 'redux-persist/lib/storage' // defaults to localStorage for web

// Reducers
import teachers from './teachers';
import students from './students';
import account from './account';
import external from './external';
import valuations from './valuations';
import files from './files';
import notification from './notification';

const rootPersistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [
    'students',
    'teachers',
    'account',
    'external',
    'valuations',
    'files',
    'notification'
  ]
};

const accountPersistConfig = {
  key: 'account',
  storage: AsyncStorage,
  whitelist: [
    'token',
    'account',
    'logged',
    'linkedAccount',
    'tutorials',
    'info',
    'lastStream'
  ]
};

const reducers = combineReducers({
  teachers,
  students,
  account: persistReducer(accountPersistConfig, account),
  external,
  valuations,
  files,
  notification
});

export default persistReducer(rootPersistConfig, reducers);