const UPDATE_TYPES = {
  SET_VALUATION_REQUEST: 'valuations/SET_VALUATION_REQUEST',
  SET_VALUATION_FAILURE: 'valuations/SET_VALUATION_FAILURE',
  SET_VALUATION_SUCCESS: 'valuations/SET_VALUATION_SUCCESS'
};

const FETCH_TYPES = {
  VALUATIONS_PENDING_REQUEST: 'valuations/VALUATIONS_PENDING_REQUEST',
  VALUATIONS_PENDING_FAILURE: 'valuations/VALUATIONS_PENDING_FAILURE',
  VALUATIONS_PENDING_SUCCESS: 'valuations/VALUATIONS_PENDING_SUCCESS',
  VALUATIONS_MY_REQUEST: 'valuations/VALUATIONS_MY_REQUEST',
  VALUATIONS_MY_FAILURE: 'valuations/VALUATIONS_MY_FAILURE',
  VALUATIONS_MY_SUCCESS: 'valuations/VALUATIONS_MY_SUCCESS',
  VALUATIONS_MADE_REQUEST: 'valuations/VALUATIONS_MADE_REQUEST',
  VALUATIONS_MADE_FAILURE: 'valuations/VALUATIONS_MADE_FAILURE',
  VALUATIONS_MADE_SUCCESS: 'valuations/VALUATIONS_MADE_SUCCESS',
  TEACHER_VALUATIONS_REQUEST: 'valuations/TEACHER_VALUATIONS_REQUEST',
  TEACHER_VALUATIONS_FAILURE: 'valuations/TEACHER_VALUATIONS_FAILURE',
  TEACHER_VALUATIONS_SUCCESS: 'valuations/TEACHER_VALUATIONS_SUCCESS'
};

export const types = {
  ...FETCH_TYPES,
  ...UPDATE_TYPES,
  VALUATIONS_CLEAN_MESSAGE: 'valuations/VALUATIONS_CLEAN_MESSAGE'
};

export const INITIAL_STATE = {
  pending: [],
  myValuations: [],
  valuationsMade: [],
  teacherValuations: [],
  isFetching: false,
  message: '',
  success: false
};

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  const { pending, myValuations, valuationsMade, teacherValuations } = action;
  switch (action.type) {
    case types.VALUATIONS_CLEAN_MESSAGE:
      return { ...state, message: null };
    // FOR FETCH INFO
    case types.VALUATIONS_PENDING_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.VALUATIONS_PENDING_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.VALUATIONS_PENDING_SUCCESS:
      return { ...state, isFetching: false, pending };
    case types.VALUATIONS_MY_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.VALUATIONS_MY_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.VALUATIONS_MY_SUCCESS:
      return { ...state, isFetching: false, myValuations };
    case types.VALUATIONS_MADE_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.VALUATIONS_MADE_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.VALUATIONS_MADE_SUCCESS:
      return { ...state, isFetching: false, valuationsMade };
    case types.TEACHER_VALUATIONS_REQUEST:
      return { ...state, isFetching: true, message: '', teacherValuations: [] };
    case types.TEACHER_VALUATIONS_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHER_VALUATIONS_SUCCESS:
      return { ...state, isFetching: false, teacherValuations };
    // FOR UPDATE INFO
    case types.SET_VALUATION_REQUEST:
      return { ...state, isFetching: true, message: '', success: false };
    case types.SET_VALUATION_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.SET_VALUATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messsage: action.message,
        success: true
      };
    default:
      return state;
  }
};

const UPDATE_ACTIONS = {
  setValuationRequest: () => ({
    type: types.SET_VALUATION_REQUEST
  }),
  setValuationFailure: (message) => ({
    type: types.SET_VALUATION_FAILURE,
    message
  }),
  setValuationSuccess: (message) => ({
    type: types.SET_VALUATION_SUCCESS,
    message
  })
};

const FETCH_ACTIONS = {
  pendingRequest: () => ({
    type: types.VALUATIONS_PENDING_REQUEST
  }),
  pendingFailure: (message) => ({
    type: types.VALUATIONS_PENDING_FAILURE,
    message
  }),
  pendingSuccess: (pending) => ({
    type: types.VALUATIONS_PENDING_SUCCESS,
    pending
  }),
  myValuationsRequest: () => ({
    type: types.VALUATIONS_MY_REQUEST
  }),
  myValuationsFailure: (message) => ({
    type: types.VALUATIONS_MY_FAILURE,
    message
  }),
  myValuationsSuccess: (myValuations) => ({
    type: types.VALUATIONS_MY_SUCCESS,
    myValuations
  }),
  valuationsMadeRequest: () => ({
    type: types.VALUATIONS_MADE_REQUEST
  }),
  valuationsMadeFailure: (message) => ({
    type: types.VALUATIONS_MADE_FAILURE,
    message
  }),
  valuationsMadeSuccess: (valuationsMade) => ({
    type: types.VALUATIONS_MADE_SUCCESS,
    valuationsMade
  }),
  teacherValuationsRequest: () => ({
    type: types.TEACHER_VALUATIONS_REQUEST
  }),
  teacherValuationsFailure: (message) => ({
    type: types.TEACHER_VALUATIONS_FAILURE,
    message
  }),
  teacherValuationsSuccess: (teacherValuations) => ({
    type: types.TEACHER_VALUATIONS_SUCCESS,
    teacherValuations
  })
};

export const actions = {
  ...FETCH_ACTIONS,
  ...UPDATE_ACTIONS,
  reset: () => ({
    type: types.VALUATIONS_CLEAN_MESSAGE
  })
};

export const selectors = {
  getTeacherValuations: ({ valuations }) => valuations.teacherValuations,
  getValuationsMade: ({ valuations }) => valuations.valuationsMade,
  getMyValuations: ({ valuations }) => valuations.myValuations,
  getPending: ({ valuations }) => valuations.pending,
  isFetching: ({ valuations }) => valuations.isFetching,
  getMessage: ({ valuations }) => valuations.message,
  getSuccess: ({ valuations }) => valuations.success
};
