import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { IP, PORT, STREAM } from '../../middleware/constants';
import { randomCode } from '../../utils/strings';
import Chat from '../../components/chat/Chat';
import ToolTip from '../../components/ToolTip';
import { selectors, actions } from '../../reducers/account';
import { devicesMax } from '../../utils/constants';
import { fonts, colors } from '../../utils/constants';
import { MdScreenShare } from 'react-icons/md';
import { GoMute, GoUnmute } from 'react-icons/go';
import { FiCamera, FiCameraOff } from 'react-icons/fi';

// const mergeAudioStreams = (desktopStream, voiceStream) => {
//   const context = new AudioContext();
//   const destination = context.createMediaStreamDestination();
//   if (desktopStream && desktopStream.getAudioTracks().length > 0) {
//     // If you don't want to share Audio from the desktop it should still work with just the voice.
//     const source1 = context.createMediaStreamSource(desktopStream);
//     const desktopGain = context.createGain();
//     desktopGain.gain.value = 0.7;
//     source1.connect(desktopGain).connect(destination);
//   }
//   if (voiceStream && voiceStream.getAudioTracks().length > 0) {
//     const source2 = context.createMediaStreamSource(voiceStream);
//     const voiceGain = context.createGain();
//     voiceGain.gain.value = 0.7;
//     source2.connect(voiceGain).connect(destination);
//   }
//   return destination.stream.getAudioTracks();
// };

let socket;
let rec;

// STREAMS
let stream;
let camStream;
let desktopStream;

const videoConstraints = {
  width: { ideal: 640, max: 640 },
  height: { ideal: 480, max: 480 },
  frameRate: { ideal: 14, max: 16 },
};

// TRACKS
let videoTracks;
let audioTracks;

const Stream = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectors.getAccount);
  const lastStream = useSelector(selectors.getLastStream);
  const id = useRef(null);
  const [tool, setTool] = useState(false);
  const [values, setValues] = useState({
    isVideo: true,
    isMicrophone: true,
    isShare: false,
  });
  const videoRef = useRef();

  const handleCopyUrl = () => {
    copy(`https://estuap.com/online/${id.current}`);
    setTool(true);
    setTimeout(() => {
      setTool(false);
    }, 2000);
  };

  const video_show = (stream) => {
    if ('srcObject' in videoRef.current) {
      videoRef.current.srcObject = stream;
    } else {
      videoRef.current.src = window.URL.createObjectURL(stream);
    }
  };

  const show_output = (str) => {
    console.log(str);
  };

  const connectServer = (url) => {
    socket.on('connect_error', function () {
      console.log('Connection Failed');
    });
    socket.on('message', function (m) {
      console.log('recv server message', m);
      show_output('SERVER:' + m);
    });
    socket.on('fatal', function (m) {
      show_output('ERROR: unexpected:' + m);
      console.log('server is reload!');
    });
    socket.on('ffmpeg_stderr', function (m) {
      show_output('FFMPEG:' + m);
    });
    socket.on('disconnect', function () {
      show_output('ERROR: server disconnected!');
      console.log('server is reload!');
    });
    socket.emit('config_rtmpDestination', url);
    socket.emit('start', 'start');
    console.log('connect server successful');
  };

  const initRecord = (stream) => {
    if (rec) {
      try {
        rec.stop();
      } catch (ex) {
        console.log(ex);
      }
    } //'-use_wallclock_as_timestamps', '1', '-r', '16',
    rec = new MediaRecorder(stream, { mimeType: 'video/webm;codecs=h264,opus' });
    rec.ignoreMutedMedia = true;
    rec.start(0);
    // rec.onstop = (e) => stream.stop();
    rec.onstop = (e) => {
      console.log(e);
    };
    rec.ondataavailable = (e) => {
      socket.emit('binarystream', e.data);
    };
  };

  const initMedia = async () => {
    setValues({ isVideo: true, isMicrophone: true, isShare: false });
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      navigator.webkitGetUserMedia;
    try {
      camStream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
        audio: true,
      });
      videoTracks = camStream.getVideoTracks();
      audioTracks = camStream.getAudioTracks();
      const tracks = [...videoTracks, ...audioTracks];
      stream = new MediaStream(tracks);
      video_show(stream);
      videoRef.current.play();
      initRecord(stream);
    } catch (ex) {
      alert(ex);
    }
  };

  const stopAllStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (camStream) {
      camStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (desktopStream) {
      desktopStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };
  const showModal = (message, seconds) => {
    dispatch(
      actions.updateModal({
        show: true,
        text: message,
      })
    );
    setTimeout(() => {
      dispatch(actions.updateModal({ finish: true }));
      setTimeout(() => {
        dispatch(actions.updateModal({ finish: false, show: false, text: null }));
      }, 2000);
    }, 1000 * seconds);
  };

  const getCode = () => {
    if (
      lastStream &&
      new Date().getTime() - lastStream.time <= 23 * 60 * 60 * 1000
    ) {
      id.current = lastStream.id;
    } else {
      const newId = randomCode(6);
      id.current = newId;
      dispatch(actions.setLastStream(newId));
      showModal(
        'Aguarde unos segundos, estamos configurando su sala de streaming para que puedas dar clase',
        15
      );
    }
  };

  const handleMicrophone = () => {
    if (values.isMicrophone) {
      setValues({ ...values, isMicrophone: false });
      audioTracks.forEach((track) => {
        track.enabled = false;
      });
    } else {
      setValues({ ...values, isMicrophone: true });
      audioTracks.forEach((track) => {
        track.enabled = true;
      });
    }
  };

  const handleVideo = () => {
    if (values.isVideo) {
      setValues({ ...values, isVideo: false });
      videoTracks.forEach((track) => {
        track.enabled = false;
      });
    } else {
      setValues({ ...values, isVideo: true });
      videoTracks.forEach((track) => {
        track.enabled = true;
      });
    }
  };

  const handleDesktop = async () => {
    if (values.isShare) {
      setValues({ ...values, isShare: false });
      if (desktopStream) {
        stream.removeTrack(desktopStream.getVideoTracks()[0]);
      }
      stream.addTrack(videoTracks[0]);
    } else {
      setValues({ ...values, isShare: true });
      desktopStream = await navigator.mediaDevices.getDisplayMedia({
        video: videoConstraints,
      });
      stream.removeTrack(videoTracks[0]);
      stream.addTrack(desktopStream.getVideoTracks()[0]);
    }
    socket_disconect();
    setTimeout(() => {
      socket_connect();
      initRecord(stream);
      showModal('Configurando video...', 6);
    }, 500);
  };

  const socket_connect = () => {
    socket = io.connect(`${IP}:${PORT}`, {
      secure: true,
      transports: ['websocket', 'polling', 'flashsocket'],
    });
    connectServer(`${STREAM}/${id.current}`);
  };

  const socket_disconect = () => {
    socket.emit('disconnect');
    socket.close();
  };

  useEffect(() => {
    getCode();
    socket_connect();
    initMedia();
    return () => {
      socket_disconect();
      stopAllStream();
    };
  }, []);

  return (
    <Container>
      <Content>
        <Video ref={videoRef} id="output_video" autoplay muted />
        <Buttons>
          <VideoButton selected={values.isShare} onClick={handleDesktop}>
            <ShareWindow color="white" size={16} />
            <VideoButtonText>Compartir pantalla</VideoButtonText>
          </VideoButton>
          <VideoButton selected={!values.isMicrophone} onClick={handleMicrophone}>
            {values.isMicrophone ? (
              <UnmuteSound color="white" size={16} />
            ) : (
              <MuteSound color="white" size={16} />
            )}
            <VideoButtonText>
              {values.isMicrophone ? 'Mutear ' : 'Desmutear '}sonido
            </VideoButtonText>
          </VideoButton>
          {values.isShare ? null : (
            <VideoButton selected={!values.isVideo} onClick={handleVideo}>
              {values.isVideo ? (
                <CameraOn color="white" size={16} />
              ) : (
                <CameraOff color="white" size={16} />
              )}
              <VideoButtonText>
                {values.isVideo ? 'Apagar ' : 'Prender '}cámara
              </VideoButtonText>
            </VideoButton>
          )}
        </Buttons>
      </Content>
      <ChatContent>
        <Header>
          <Column>
            <Url left>
              El enlace que tendrá a continuación es el que debe compartir con sus
              alumnos, cambia cada 24 horas una vez iniciada la transmisión. También
              puede comunicarle a sus alumnos que ingresen al sitio y en el buscador
              de la plataforma ingresen el código de esta clase. El mismo se
              encuentra de bajo del enlace a compartir.
            </Url>
            <Line></Line>
            <Url onClick={() => copy(`https://estuap.com/online/${id.current}`)}>
              {`https://estuap.com/online/${id.current}`}
            </Url>
            <Text onClick={handleCopyUrl}>
              Pinche aquí para copiar el enlace
              <ToolTip text="¡Copiado!" visible={tool} />
            </Text>
            <Row>
              <Code>Código para compartir: </Code>
              <Url onClick={() => copy(`${id.current}`)}>{`${id.current}`}</Url>
            </Row>
          </Column>
        </Header>
        {id.current && (
          <Chat idChannel={id.current} name={`${account.name} ${account.surname}`} />
        )}
      </ChatContent>
    </Container>
  );
};

const Container = styled.div`
  background-color: #eaeaea;
  margin-top: 15px;
  position: relative;
  display: flex;
  @media ${devicesMax.tablet} {
    flex-direction: column;
  }
  @media ${devicesMax.tablet} and (orientation: portrait) {
    margin-top: 65px;
  }
`;

const Header = styled.div`
  background-color: #1692ff;
  display: flex;
  align-items: center;
  justify-content: 'space-evenly';
  margin: 0px 10px 20px 10px;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 1px;
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 80vh;
  box-sizing: border-box;
  margin: 1px 1px 1px 1px;
  @media ${devicesMax.tablet} {
    width: 100vw;
    padding: 1px 1px 1px 1px;
  }
  @media ${devicesMax.tablet} and (orientation: landscape) {
    height: 150vh;
    padding: 1px 1px 1px 1px;
  }
`;

const Content = styled.div`
  position: relative;
`;

const Video = styled.video`
  width: 50vw;
  height: 80vh;
  margin: 1px 1px 1px 1px;
  background-color: ${colors.black};
  @media ${devicesMax.tablet} {
    width: 100vw;
    margin: 1px 1px 1px 1px;
  }
`;

const Buttons = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

const ShareWindow = styled(MdScreenShare)`
  padding: 5px;
  border-radius: 50px;
  background-color: #007aff;
  border: 1px solid white;
`;

const MuteSound = styled(GoMute)`
  padding: 5px;
  border-radius: 50px;
  background-color: #007aff;
  border: 1px solid white;
`;

const UnmuteSound = styled(GoUnmute)`
  padding: 5px;
  border-radius: 50px;
  background-color: #007aff;
  border: 1px solid white;
`;
const CameraOn = styled(FiCamera)`
  padding: 5px;
  border-radius: 50px;
  background-color: #007aff;
  border: 1px solid white;
`;

const CameraOff = styled(FiCameraOff)`
  padding: 5px;
  border-radius: 50px;
  background-color: #007aff;
  border: 1px solid white;
`;

const VideoButton = styled.button`
  margin-left: 10px;
  background-color: ${({ selected }) => (selected ? 'darkred' : '#20c601')};
  border: none;
  padding: 5px 10px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  outline: none;
  cursor: pointer;
`;

const VideoButtonText = styled.p`
  margin: 0 5px;
  font-family: ${fonts.principal};
  color: white;
`;

const Url = styled.a`
  font-family: ${fonts.principal};
  font-weight: bold;
  color: white;
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  @media (max-width: 350px) {
    font-size: 0.6em;
  }
`;

const Text = styled.button`
  outline: none;
  border: none;
  margin: 10px auto;
  font-family: ${fonts.principal};
  font-size: 0.9em;
  color: white;
  width: 90%;
  cursor: pointer;
  background-color: #20c601;
  padding: 5px;
  border-radius: 5px;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: 11px 0;
  background-color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Code = styled.p`
  margin: 0;
  color: white;
  margin-right: 10px;
`;

export default Stream;
