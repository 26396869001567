// Principal libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

// Extras
import { selectors, actions } from '../reducers/account';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isLogged = useSelector(selectors.isLogged);


  if (!isLogged) {
    dispatch(actions.showLogin(true));
  }

  return (
    <Route
      {...rest}
      render={(props) => (isLogged ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
