// Principal libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// Components
import Box from './components/Box';
import Modal from '../../components/Modal';
import Detail from '../home/detail/Detail';

// Extras
import actions from '../../actions/teachers';
import { selectors } from '../../reducers/teachers';

const Search = () => {
  const categories = useSelector(selectors.getSearchs);
  const [modal, setModal] = useState({ show: false, people: null, title: '' });
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(actions.teachersSearchRequest({ category: id }));
  }, [id]);

  const closeModal = () => setModal({ show: !modal.show, people: null, title: '' });

  return (
    <Container>
      {categories.map((item) => (
        <Box
          key={item.id}
          item={item}
          onPress={() =>
            setModal({ ...modal, people: item, show: !modal.show, title: '' })
          }
        />
      ))}
      <Modal
        width="35%"
        fullScreenOnMobile
        minHeight="90%"
        background="#011220"
        open={modal.show}
        onClick={closeModal}
        title={modal.title}
        subTitle
      >
        <Detail item={modal.people} setModal={setModal} modal={modal} />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default Search;
