/**
 * This file contain handle date functions
 * For example: transform month and year to MariaDB date format
 */

/**
 * "mm/yy => yyyy/mm/01"
 * @param {string} date mm/yy
 * @return {string} 20yy/mm/01
 */
export const parseDate = (date) => {
  let parts = date.split('/');
  if (parts.length === 1) parts = date.split('-');
  return parts[1].length === 2
    ? `20${parts[1]}/${parts[0]}/01`
    : `${parts[1]}/${parts[0]}/01`;
};

/**
 * "yyyy-mm-dd => dd/mm/yyyy"
 * @param {string} date yyyy/mm/dd
 * @return {string} dd/mm/yyyy
 */
export const MariaDbToDate = (date) => {
  if (!date) {
    return '';
  }
  let parts = date.split('/');
  if (parts.length === 1) parts = date.split('-');
  return `${parts[2].substring(0, 2)}/${parts[1]}/${parts[0]}`;
};

/**
 * "yyyy-mm-ddTHH:mm:ss => dd/mm/yyyy"
 * @param {string} date yyyy/mm/dd
 * @return {string} dd/mm/yyyy
 */
export const toShortDate = (date) => {
  return date.substring(0, 10);
};

/**
 * "yyyy-mm-ddTHH:mm:ss => HH:mm"
 * @param {string} date yyyy-mm-ddTHH:mm:ss
 * @return {string} HH:mm
 */
export const toTime = (date) => {
  const d = new Date(date);
  let hour = `${d.getHours()}`;
  let minute = `${d.getMinutes()}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minute.length < 2) minute = `0${minute}`;
  return [hour, minute].join(':');
};

/**
 * "yyyy-mm-ddTHH:mm:ss => HH:mm"
 * @param {string} date HH:mm
 * @return {string} HH:mm
 */
export const addHour = (date, hours) => {
  const d = new Date(date);
  let hour = `${d.getHours() + hours}`;
  let minute = `${d.getMinutes()}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minute.length < 2) minute = `0${minute}`;
  return [hour, minute].join(':');
};

/**
 * "dd/mm/yyyy => yyyy-mm-dd => "
 * @param {string} date dd/mm/yyyy
 * @return {string} yyyy/mm/dd
 */
export const DateToMariaDb = (date) => {
  let parts = date.split('/');
  if (parts.length === 1) parts = date.split('-');
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

/**
 * "{ day: dd, month: mm, year: yyyy } => yyyy-mm-dd => "
 * @param {string} date { day: dd, month: mm, year: yyyy }
 * @return {string} yyyy/mm/dd
 */
export const objectToDate = (date) => `${date.year}-${date.month}-${date.day}`;

/**
 * "Thu Jun 28 2001 => dd/mm/yyyy => "
 * @param {string} date Thu Jun 28 2001
 * @return {string} dd/mm/yyyy
 */
export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [day, month, year].join('/');
};

/**
 * "Thu Jun 28 2001 => yyyy/mm/dd => "
 * @param {string} date Thu Jun 28 2001
 * @return {string} yyyy/mm/dd
 */
export const mariaFormatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
};

/**
 * "yyyy-mm-ddTHH:mm:ss => yyyy/mm/dd HH:mm:ss => "
 * @param {string} date yyyy-mm-ddTHH:mm:ss
 * @return {string} yyyy/mm/dd HH:mm:ss
 */
export const format = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  let hour = `${d.getHours()}`;
  let minute = `${d.getMinutes()}`;
  let seconds = `${d.getSeconds()}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minute.length < 2) minute = `0${minute}`;
  if (seconds.length < 2) seconds = `0${seconds}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const dt = [year, month, day].join('-');
  const time = [hour, minute, seconds].join(':');
  return `${dt} ${time}`;
};

/**
 * "yyyy-mm-ddTHH:mm:ss => yyyy/mm/dd HH:mm:ss => "
 * @param {string} date yyyy-mm-ddTHH:mm:ss
 * @return {string} yyyy/mm/dd HH:mm:ss
 */
export const formatDelay = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  let hour = `${d.getHours()}`;
  let minute = `${d.getMinutes()}`;
  d.setSeconds(d.getSeconds() + 60);
  let seconds = `${d.getSeconds()}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minute.length < 2) minute = `0${minute}`;
  if (seconds.length < 2) seconds = `0${seconds}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const dt = [year, month, day].join('-');
  const time = [hour, minute, seconds].join(':');
  return `${dt} ${time}`;
};

/**
 * "yyyy-mm-ddTHH:mm:ss => dd/mm/yyyy"
 * @param {string} date yyyy-mm-ddTHH:mm:ss
 * @return {string} dd/mm/yyyy
 */
export const shortFormat = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const dt = [day, month, year].join('/');
  return dt;
};

export const days = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado'
];

/**
 * dd/mm/yyyy => DayName
 * @param {String} date
 */
export const getDayNameFromDateString = (date) => {
  const dateParts = date.split('/');
  const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return days[dateObject.getDay()];
};
