/* eslint-disable global-require */
const colors = {
  yellow: '#FFDD03', // Map button
  orange: '#F79919', // Ex: Title (Advance search)
  violet: '#a220af', // Ex: Categories (Notification)
  black: '#011220', // Text's
  green: '#59D507', // Ex: More info button (Simple search)
  darkGreen: '#007714', // Ex: Price's (Home)
  red: '#F20000', // Ex: Notification pop
  lightRed: '#DC4C41', // GOOGLE ICON
  gray: '#E2E2E2', // Background
  lightgray: '#F7F7F7', // Background
  darkgray: '#808080', // Undefined
  white: '#FFFFFF', // Undefined
  skyBlue: '#1692FF', // Principal
  blue: '#073C6B', // Ex: More info button (Home)
  simpleblue: '#127DDB', // Notifications
  lightblue: '#0062B2', // Background of valuations,
  bluepro: '#002D55', // Estuap Pro
  skyviolet: '#5685AF',
  simpleviolet: '#818BB9',
  lightviolet: '#5665A8',
  darkviolet: '#375D80'
};

const fonts = {
  principal: 'Helvetica', // Normal text
  principalBold: 'Helvetica', // Title's
  secundary: 'Raleway', // Numbers Bold
  muli: 'Muli' // Numbers
};

const config = {
  minPrice: 150,
  maxSize: 10000000, // Byte 10 MB
  timeOut: 10000 // 10 Seconds
};

const LANGUAGES = [
  { name: 'Español', sourceFlag: '../assets/images/spainflag.png' },
  { name: 'Inglés', sourceFlag: '../assets/images/usaflag.png' },
  { name: 'Portugués', sourceFlag: '../assets/images/brazilflag.png' },
  { name: 'Francés', sourceFlag: '../assets/images/franceflag.png' },
  { name: 'Alemán', sourceFlag: '../assets/images/germanyflag.png' },
  { name: 'Chino', sourceFlag: '../assets/images/chinaflag.png' }
];

const getLanguageById = (id) => {
  if (id === -1) return { name: 'Otro' };
  if (id >= LANGUAGES.length) return { name: '' };
  return LANGUAGES[id];
};

const LANGUAGE_LEVELS = ['Materno', 'Básico', 'Intermedio', 'Avanzado'];

const PHONE_COUNTRY = '+598';

const LINKED_ACCOUNTS_NUMBERS = {
  NONE: 0,
  FACEBOOK: 1,
  GOOGLE: 2
};

const SECRET_WORD = ';;a!:+?2JEk)z!n8X!&qL=O$Q1Q:::';

const DEPARTAMENTS = [
  { departament: 'Canelones' },
  { departament: 'Maldonado' },
  { departament: 'Treinta y Tres' },
  { departament: 'Cerro Largo' },
  { departament: 'Lavalleja' },
  { departament: 'Tacuarembó' },
  { departament: 'Colonia' },
  { departament: 'Montevideo' },
  { departament: 'Artigas' },
  { departament: 'Salto' },
  { departament: 'Paysandú' },
  { departament: 'Rivera' },
  { departament: 'Rocha' },
  { departament: 'San José' },
  { departament: 'Florida' },
  { departament: 'Flores' },
  { departament: 'Soriano' },
  { departament: 'Río Negro' },
  { departament: 'Durazno' }
];

const displaySizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

const devices = {
  mobileS: `(min-width: ${displaySizes.mobileS})`,
  mobileM: `(min-width: ${displaySizes.mobileM})`,
  mobileL: `(min-width: ${displaySizes.mobileL})`,
  tablet: `(min-width: ${displaySizes.tablet})`,
  laptop: `(min-width: ${displaySizes.laptop})`,
  laptopL: `(min-width: ${displaySizes.laptopL})`,
  desktop: `(min-width: ${displaySizes.desktop})`,
  desktopL: `(min-width: ${displaySizes.desktop})`
};

const devicesMax = {
  mobileS: `(max-width: ${displaySizes.mobileS})`,
  mobileM: `(max-width: ${displaySizes.mobileM})`,
  mobileL: `(max-width: ${displaySizes.mobileL})`,
  tablet: `(max-width: ${displaySizes.tablet})`,
  laptop: `(max-width: ${displaySizes.laptop})`,
  laptopL: `(max-width: ${displaySizes.laptopL})`,
  desktop: `(max-width: ${displaySizes.desktop})`,
  desktopL: `(max-width: ${displaySizes.desktop})`
};

export {
  colors,
  fonts,
  config,
  LANGUAGE_LEVELS,
  PHONE_COUNTRY,
  LINKED_ACCOUNTS_NUMBERS,
  SECRET_WORD,
  DEPARTAMENTS,
  getLanguageById,
  devices,
  devicesMax
};
