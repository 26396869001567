// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';

// Components
import Stars from '../../../components/Stars';

const Box = ({ item, onPress }) => (
  <Container>
    <Avatar src={item.photo} />
    <Column>
      <Text>{`Precio del servicio: $${item.price}`}</Text>
      <Row>
        <Text>Valoración:</Text>
        <Stars zMargin quantity={item.rate} size={12} />
      </Row>
    </Column>
    <Button onClick={onPress}>
      <FaInfoCircle color="#95CDFF" size={30} />
      <ButtonTitle>Consultar</ButtonTitle>
    </Button>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  border-radius: 5px;
  background-color: #333c5d;
  padding: 5px 10px;
  margin: 10px auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 0px 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Text = styled.p`
  color: #818bb9;
  font-weight: bold;
  font-size: 1em;
  margin: 5px 0;
`;

const Avatar = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 50px;
  margin:5px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

const ButtonTitle = styled.p`
  margin: 0;
  color: #95cdff;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px;
`;

export default Box;
