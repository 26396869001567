export const types = {
  NOTIFICATION_FETCH_REQUEST: 'notification/NOTIFICATION_FETCH_REQUEST',
  NOTIFICATION_FETCH_FAILURE: 'notification/NOTIFICATION_FETCH_FAILURE',
  NOTIFICATION_FETCH_SUCCESS: 'notification/NOTIFICATION_FETCH_SUCCESS',
  NOTIFICATION_DELETE_REQUEST: 'notification/NOTIFICATION_DELETE_REQUEST',
  NOTIFICATION_DELETE_FAILURE: 'notification/NOTIFICATION_DELETE_FAILURE',
  NOTIFICATION_DELETE_SUCCESS: 'notification/NOTIFICATION_DELETE_SUCCESS',
  NOTIFICATION_QUANTITY_REQUEST: 'notification/NOTIFICATION_QUANTITY_REQUEST',
  NOTIFICATION_QUANTITY_FAILURE: 'notification/NOTIFICATION_QUANTITY_FAILURE',
  NOTIFICATION_QUANTITY_SUCCESS: 'notification/NOTIFICATION_QUANTITY_SUCCESS'
};

export const INITIAL_STATE = {
  notifications: [],
  isFetching: false,
  message: null,
  quantity: 0
};

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  switch (action.type) {
    case types.NOTIFICATION_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case types.NOTIFICATION_FETCH_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.NOTIFICATION_FETCH_SUCCESS:
      return { ...state, isFetching: false, notifications: action.notifications };
    case types.NOTIFICATION_DELETE_REQUEST:
      return { ...state, isFetching: true };
    case types.NOTIFICATION_DELETE_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.NOTIFICATION_DELETE_SUCCESS:
      return { ...state, isFetching: false, notifications: action.notifications };
    case types.NOTIFICATION_QUANTITY_REQUEST:
      return { ...state, isFetching: true };
    case types.NOTIFICATION_QUANTITY_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.NOTIFICATION_QUANTITY_SUCCESS:
      return { ...state, isFetching: false, quantity: action.quantity };
    default:
      return state;
  }
};

export const actions = {
  getNotificationsRequest: () => ({
    type: types.NOTIFICATION_FETCH_REQUEST
  }),
  getNotificationsFailure: (message) => ({
    type: types.NOTIFICATION_FETCH_FAILURE,
    message
  }),
  getNotificationsSuccess: (notifications) => ({
    type: types.NOTIFICATION_FETCH_SUCCESS,
    notifications
  }),
  deleteNotificationRequest: () => ({
    type: types.NOTIFICATION_DELETE_REQUEST
  }),
  deleteNotificationFailure: (message) => ({
    type: types.NOTIFICATION_DELETE_FAILURE,
    message
  }),
  deleteNotificationSuccess: (notifications) => ({
    type: types.NOTIFICATION_DELETE_SUCCESS,
    notifications
  }),
  quantityNotificationRequest: () => ({
    type: types.NOTIFICATION_QUANTITY_REQUEST
  }),
  quantityNotificationFailure: (message) => ({
    type: types.NOTIFICATION_QUANTITY_FAILURE,
    message
  }),
  quantityNotificationSuccess: (quantity) => ({
    type: types.NOTIFICATION_QUANTITY_SUCCESS,
    quantity
  })
};

export const selectors = {
  getNotification: ({ notification }) => notification.notifications,
  isFetching: ({ notification }) => notification.isFetching,
  getMessage: ({ notification }) => notification.message,
  getQuantity: ({ notification }) => notification.quantity
};
