import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from '../utils/constants';
import TickMark from './extras/TickMark';
import { selectors } from '../reducers/account';

const ModalFetching = () => {
  const { show, text, finish } = useSelector(selectors.showModal);
  return show ? (
    <>
      <Background />
      <Box>
        {finish ? (
          <TickMark />
        ) : (
          <>
            <Text>{text}</Text>
            <Spin />
          </>
        )}
      </Box>
    </>
  ) : null;
};

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  opacity: 0.9;
  z-index: 6;
`;

const Text = styled.p`
  color: ${colors.black};
  text-align: center;
`;

const Box = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  border-radius: 10px;
  opacity: 1;
  z-index: 7;
`;

const spin_safari = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spin = styled.div`
  border: 8px solid ${colors.blue};
  border-radius: 50%;
  border-top: 10px solid ${colors.orange};
  width: 40px;
  height: 40px;
  -webkit-animation: ${spin_safari} 0.8s linear infinite;
  animation: ${spin} 0.6s linear infinite;
`;

export default ModalFetching;
