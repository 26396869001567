import React from 'react';

const Download = () => {
  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=com.estuap'
      );
    } else if (/iPad|iPhone|Safari|iPod/.test(userAgent) && !window.MSStream) {
      window.location.replace('https://apps.apple.com/uy/app/estuap/id1470447379');
    } else {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=com.estuap'
      );
    }
  };
  getMobileOperatingSystem();

  return <></>;
};

export default Download;
