// Principal libraries
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Extras
import actions from '../../actions/account';

const Account = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  return (
    <Container>
      <Text>Cambiar contraseña</Text>
      <Text>Tarjetas de crédito</Text>
      <Text>Notificaciones</Text>
      <Text>Reportar un problema</Text>
      <Text>Acerca de Estuap</Text>
      <Text>Compartir Estuap</Text>
      <Content>
        <Button to="/" margin onClick={handleLogout}>
          <Title>Cerrar sesión</Title>
        </Button>
        <Button to="/">
          <Title>Eliminar cuenta</Title>
        </Button>
        <Description>
          Ten en cuenta que perderas tu valoración y cartera eliminando tu cuenta
        </Description>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled(Link)`
  font-weight: bold;
  margin: 2px 0px;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #818bb9;
`;

const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin: ${(props) => (props.margin ? '40px 0' : '0')};
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

const Title = styled.p`
  margin: 0;
  color: darkgray;
  font-weight: bold;
  font-size: 1.2em;
`;

const Description = styled.p`
  color: darkgray;
  margin: 0;
  font-size: 0.8em;
`;

export default Account;
