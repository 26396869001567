// Principal libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkTo } from 'react-router-dom';
import TextLoop from 'react-text-loop';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import navigate from "../../../components/extras/navigate";

// import Cell from "../../../components/icn/Icons";

// Extras
import { actions, selectors } from '../../../reducers/account';
import { fonts, colors } from '../../../utils/constants';
import {ZoomIn, FadeInLeft} from "../Keyframes";





const Header = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  // const isLogged = useSelector(selectors.isLogged);
  
  // const [value, setValue] = useState('');

  

  // const handleKey = (e) => {
  //   if (value.length && e.key === 'Enter') {
  //     history.push(`/online/${value}`);
  //   }
  // };

  const handleLogin = () => {
    dispatch(actions.showLogin(true));
  };

  return (
    <Container>
      <Content>
        <Image src="assets/images/logo.png" />
        <RowText>
          <Text>Estuap es una aplicación pensada para dar la mejor solución académica a alumnos, estudiantes y docentes.
            <br/> <br/>Es de muy fácil uso, através de geolocalización podrás encontrar, agendar o concretar 
            clases particulares con el docente que necesites más cercano a ti y en el horario que te más convenga. </Text>
          {/* <TextLoop interval={4000}>
            <Text mark yellow>
             Uruguay
            </Text>
            <Text mark yellow>
               Argentina
            </Text>
          </TextLoop>
          <br/>
          <br/>
          <Text> ¡Es super sencillo de utilizar! </Text> */}
        </RowText>
        {/* <Input
          placeholder="Pincha aquí para ingresar el código de su profesor"
          value={value}
          onChange={({ target }) => {
            setValue(target.value);
          }}
          onKeyDown={handleKey}
        /> */}
        
        {/* <Row>
          {!isLogged && (
            <>
              <Button to="/register">Registrate como profesor</Button>
              <Button delay onClick={handleLogin}>
                Iniciar sesión
              </Button>
            </>
          )}
        </Row> */}
      </Content>
      <ContentTwo> 
      <TextT> Disponible en </TextT> 
      <LinkA href="https://www.estuap.com/descarga" target="_blank" >
          <ImageStoreO src="assets/images/playstore.png"/>
          <ImageStoreT src="assets/images/appstore.png" /> 
      </LinkA>

      </ContentTwo>
      <Line>
        {/* <Option>
          <NavLink href="/#tutorials">¿Cómo empezar?</NavLink>
        </Option> */}
        <Option>
          <NavLink href="/#footer">Contáctenos</NavLink>
        </Option>
      </Line>
    </Container>
  );
};
const ContentTwo = styled.div`
  display:flex;
  align-items: center;
  width: 50%;
  height: 70px;
  margin:0px 0 20px 80px;
  flex-direction: row;
  @media (max-width:813px){
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 0px;
    margin: 0px;
    height: 10em;
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100vw;
  height: 100vh;
  background-image: url("background.png");
`;

const Content = styled.div`
  display: flex;
  width:60%;
  flex-direction: column;
  flex: 1;
  justify-content: left;
  align-items: left;
  margin-top: 5vh;
  @media (max-width:813px){
    text-align: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;
  }
`;

const RowText = styled.div`
  animation: ${ZoomIn} 3.5s;
  margin-left: 80px;
  @media (max-width:813px){
    padding:20px;
    margin: 0px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  width: 100%;
  margin: 10px 80px 0 80px;
  animation: ${FadeInLeft} 4s;
  @media (max-width: 813px){
    flex-wrap: wrap;
    width: 70%;
    margin: 20px 0px 5px 0px;
  }
`;
const Line = styled.div`
  display: flex;
  justify-content: left;
  margin: 0 0 0 80px;
  width: 100%;
  box-sizing: border-box;
  animation: ${ZoomIn} 3s;
  @media (max-width:813px){
    text-align: center;
    justify-content: center;
    margin: 20px 0 10px 0;
  }
`;

const Option = styled.li`
  display: inline-block;
  color: gray;
  margin: 5px;
  padding: 20px 0;
  
`;

const Link = styled(LinkTo)`
  color: lightgray;
  transition: all 0.5s;
  :hover {
    color: white;
  }
`;

const NavLink = styled.a`
  color: lightgray;
  transition: all 0.5s;
  :hover {
    color: white;
  }
`;

const Image = styled.img`
  margin: 20px 0px 5px 70px;
  width: 20%;
  animation: ${ZoomIn} 3s;
  @media (max-width:813px){
    width: 50%;
    margin: 20px 0px 0px 0px;
  }
`;
const ImageStoreO = styled.img`
  margin-top:10px;
  margin-bottom: 10px;
  cursor:pointer;
  width: 50%;
  animation: ${ZoomIn} 3s;
    @media (max-width:813px){
    margin-top: 20px;
    width: 100%;
  }
`;
const LinkA = styled.a`
  width: 50%;
  height: 100%;
`;

const ImageStoreT = styled.img`
  margin-top:7px;
  margin-bottom: 10px;
  width: 45%;
  padding-bottom: 2px;
  cursor:pointer;
  animation: ${ZoomIn} 3s;
    @media (max-width:813px){
    margin-top: 20px;
    width: 100%;
  }
`;


const Text = styled.span`
  font-family: ${fonts.principal};
  font-size: ${(props) => (props.small ? '0.8em' : '1.3em')};
  font-weight: ${(props) => (props.mark ? 'bold' : 'normal')};
  color: ${(props) => (props.yellow ? '#FFDD03' : 'white')};
  text-align: left;
  @media (max-width: 666px) {
    font-size: 1em;
  }
`;
const TextT = styled.span`
  font-family: ${fonts.principal};
  font-size: 1.1em;
  color: ${(props) => (props.yellow ? '#FFDD03' : 'white')};
  text-align: left;
  @media (max-width: 666px) {
    font-size: 1em;
  }
`;

const Button = styled(Link)`
  display: block;
  background-color: none;
  width: fit-content;
  padding: 10px;
  width: 250px;
  font-family: ${fonts.principal};
  text-align: center;
  font-size: 1.1em;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;
  color: white;
  margin: 50px 20px 10px 0;
  border: 2px solid white;
  box-sizing: border-box;
  :hover {
    background: none;
    border: 2px solid #1692ff;
    background-color: #1692ff;
  }
  @media (max-width: 840px) {
    font-size: 0.8em;
    width: 100%;
    margin:10px 0px;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  background: transparent;
  padding: 10px 0px;
  text-decoration: underline;
  color: whitesmoke;
  transition: all 1s;
  cursor: pointer;
  width: 55%;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 8vh;
  font-size: 1.1em;
  text-align: center;
  animation: ${FadeInLeft} 3s;
  @media (max-width: 660px) {
    font-size: 0.8em;
  }
  :focus {
    background-color: ${colors.white};
    color: black;
    width: 20%;
    text-decoration: none;
    ::placeholder {
      display:none;;
    }
  }
  ::placeholder {
    color: ${colors.white};
  }
`;

export default Header;
