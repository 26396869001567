// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../utils/constants';
import { FaUserGraduate, FaChalkboardTeacher } from 'react-icons/fa';

// Components
import Stars from '../../../components/Stars';

const Header = ({ name, rate, rateTeacher, photo }) => {
  return (
    <Container>
      <Column>
        <Image src={photo} />
      </Column>
      <Column>
        <Row>
          <FaUserGraduate color={colors.blue} size={20} />
          <Stars quantity={rate} />
        </Row>
        <Row>
          <FaChalkboardTeacher color={colors.blue} size={20} />
          <Stars quantity={rateTeacher} />
        </Row>
      </Column>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const Column = styled.div`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.p`
  margin: 10px 0;
  padding: 0;
  font-weight: bold;
  color: ${colors.black};
`;

const Image = styled.img`
  width: 170px;
  height: 170px;
  border-radius: 85px;
`;

export default Header;
