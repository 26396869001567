// Principal libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Extras
import Box from './components/Box';
import { LANGUAGE_LEVELS, getLanguageById } from '../../../../utils/constants';

const More = ({ info }) => {
  const { about, teach, studies, languages, valuations } = info;
  const [lang, setLang] = useState('');
  const [values, setValues] = useState({
    onAbout: false,
    onTeach: false,
    onStudy: false,
    onLang: false
  });

  const handleAbout = () =>
    setValues({
      onTeach: false,
      onStudy: false,
      onLang: false,
      onAbout: !values.onAbout
    });

  const handleTeach = () =>
    setValues({
      onAbout: false,
      onStudy: false,
      onLang: false,
      onTeach: !values.onTeach
    });

  const handleStudy = () =>
    setValues({
      onAbout: false,
      onTeach: false,
      onLang: false,
      onStudy: !values.onStudy
    });

  const handleLang = () =>
    setValues({
      onAbout: false,
      onTeach: false,
      onStudy: false,
      onLang: !values.onLang
    });

  useEffect(() => {
    if (languages && languages.length) {
      // eslint-disable-next-line no-shadow
      let language = '';
      languages.forEach((item) => {
        language += `${getLanguageById(item.language).name}, nivel ${
          LANGUAGE_LEVELS[item.level]
        } ${item.description && `: ${item.description}. \n`}`;
      });
      setLang(language);
    }
  }, []);

  return (
    <Container>
      <Box
        title="Sobre mi"
        description={about}
        onClick={handleAbout}
        on={values.onAbout}
      />
      <Box
        title="Trayectoria laboral"
        description={teach}
        onClick={handleTeach}
        on={values.onTeach}
      />
      <Box
        title="Estudios realizados"
        description={studies}
        onClick={handleStudy}
        on={values.onStudy}
      />
      <Box
        title="Idiomas"
        description={lang}
        onClick={handleLang}
        on={values.onLang}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  justify-content: flex-start;
`;

export default More;
