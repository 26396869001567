// Principal libaries
import React from 'react';
import styled from 'styled-components';

// Extras
import { colors, fonts } from '../../../utils/constants';

const Select = ({ value, onChange, options }) => (
  <Selector
    placeholder={value === ''}
    value={value}
    onChange={({ target }) => onChange(target.value)}
  >
    <Option value=''>Seleccione un pais</Option>
    {options.map((option) => (
      <Option value={option.value}>{option.label}</Option>
    ))}
  </Selector>
);

const Selector = styled.select`
  width: 100%;
  min-height: 25px;
  font-size: 1em;
  font-family: ${fonts.principalBold};
  padding: 10px 20px;
  text-align: flex-start;
  border-radius: 10px;
  border: 0.5px solid ${colors.black};
  background-color: ${colors.white};
  color: ${({ placeholder }) => (placeholder ? '#A9A9A9' : colors.black)};
  margin-left: 10px;
  outline: none;
  appearance: none;
`;

const Option = styled.option``;

export default Select;
