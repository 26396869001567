import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { colors, fonts, devices } from '../../utils/constants';
import DownloadApp from './components/DownloadApp';
import actions from '../../actions/teachers';
import { selectors } from '../../reducers/account';
import { selectors as teachersSelectors } from '../../reducers/teachers';
import { verify } from '../../middleware/students';
import * as validations from '../../utils/validations';
import Select from './components/Select';
import { FaEye } from 'react-icons/fa';

const Register = () => {
  const logged = useSelector(selectors.isLogged);
  const message = useSelector(teachersSelectors.getMessage);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repeatpass, setRepeatpass] = useState('');
  const [selector, setSelector] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [photo, setPhoto] = useState(null);
  const [code, setCode] = useState('');
  const [viewPass, setViewPass] = useState(false);

  const [StateMessage, setMessage] = useState(null);
  const handleChange = (setter) => (evt) => {
    setter(evt.target.value);
  };
  const handleChangeFiles = (setter) => (evt) => {
    setter(evt.target.files[0]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validations.emailValidation(username)) {
      setMessage('Introduzca un email válido');
      return;
    }
    if (password !== repeatpass) {
      setMessage('Las contraseñas no coinciden');
      return;
    }
    if (password.trim().length < 4) {
      setMessage('La contraseña debe tener 4 o mas caracteres');
      return;
    }
    const { data } = await verify({ username });
    if (data.length) {
      setMessage('El mail ya está registrado');
      return;
    }
    dispatch(
      actions.registerSchoolTeacher({
        name,
        surname,
        username,
        password,
        photo,
        birthdate: { day, month, year },
        gender: 'o',
        code,
        country: selector
      })
    );
  };

  const handleViewPass = () => {
    setViewPass(!viewPass);
  };

  if (logged) return <Redirect to="/" />;

  return (
    <Container>
      <Paper>
        <Title>Nuevo registro de profesor</Title>
        <Clarification>
          El registro es gratuito y no se cobran comisiones.
          <br /> Debes ser mayor de 18 años.
          <br /> Quedarás inscripto dentro de la plataforma web y la app.
        </Clarification>
        <form onSubmit={handleSubmit}>
          <Row>
            <Input
              required
              minLength={2}
              maxLength={50}
              type="text"
              placeholder="Nombre"
              onChange={handleChange(setName)}
              value={name}
            />
            <Input
              required
              minLength={2}
              maxLength={50}
              autocorrect="off"
              autocapitalize="none"
              type="text"
              placeholder="Apellido"
              onChange={handleChange(setSurname)}
              value={surname}
            />
          </Row>
          <Row>
            <Input
              required
              minLength={4}
              maxLength={50}
              autocorrect="off"
              autocapitalize="none"
              type="email"
              placeholder="Correo electrónico"
              onChange={handleChange(setUsername)}
              value={username}
            />
          </Row>
          <Row>
            <Input
              required
              title="La contraseña debe tener 4 o mas caracteres"
              minLength={4}
              maxLength={50}
              type={viewPass ? 'text' : 'password'}
              autocorrect="off"
              autocapitalize="none"
              placeholder="Contraseña"
              onChange={handleChange(setPassword)}
              value={password}
            />
            <Eye
              size={24}
              color="black"
              onClick={handleViewPass}
              active={viewPass}
            />
          </Row>
          <Row>
            <Input
              required
              minLength={4}
              maxLength={50}
              type={viewPass ? 'text' : 'password'}
              autocorrect="off"
              autocapitalize="none"
              placeholder="Vuelve a escribir la contraseña"
              onChange={handleChange(setRepeatpass)}
              value={repeatpass}
            />
          </Row>
          <Row>
            <Select
              value={selector}
              onChange={setSelector}
              options={[
                { label: 'Uruguay', value: 'Uruguay' },
                { label: 'Argentina', value: 'Argentina' }
              ]}
            />
          </Row>
          <Row>
            <Input
              required
              min={1}
              max={31}
              type="number"
              placeholder="Día de nacimiento"
              onChange={handleChange(setDay)}
              value={day}
            />
            <Input
              required
              min={1}
              max={12}
              type="number"
              placeholder="Mes de nacimiento"
              onChange={handleChange(setMonth)}
              value={month}
            />
            <Input
              required
              min={1900}
              max={2002}
              type="number"
              placeholder="Año de nacimiento"
              onChange={handleChange(setYear)}
              value={year}
            />
          </Row>
          <Row>
            <Label htmlFor="upload-photo">
              {photo
                ? photo.name
                : 'Suba una foto para que se visualice en su perfil'}
            </Label>
          </Row>
          <InputFile
            id="upload-photo"
            type="file"
            accept="image/x-png,image/jpeg"
            placeholder="Suba una foto para que se visualice en su perfil"
            onChange={handleChangeFiles(setPhoto)}
          />
          <Row>
            <Input
              minLength={4}
              maxLength={20}
              type="text"
              placeholder="Código de instituto (dejar vacío si no tienes)"
              onChange={handleChange(setCode)}
              value={code}
            />
          </Row>
          {StateMessage ? <Error>{StateMessage}</Error> : null}
          {message ? <Error>{message}</Error> : null}
          <TextBottom>
            Al seleccionar "Confirmar registro" estarás aceptando nuestros términos y
            condiciones, y nuestra política de privacidad.
          </TextBottom>
          <Row>
            <Button
              type="submit"
              disabled={
                !(
                  name &&
                  surname &&
                  username &&
                  password &&
                  repeatpass &&
                  day &&
                  month &&
                  year &&
                  selector.length > 0
                )
              }
              value="Confirmar registro"
            />
          </Row>
        </form>
      </Paper>
      <Column>
        <DownloadApp />
      </Column>
    </Container>
  );
};

// Containers
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90%;
  padding: 20px;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0px 10px 10px 10px;
  padding: 10px 10px 10px 15px;
  @media ${devices.laptop} {
    width: 45%;
  }
`;
const Paper = styled(Column)`
  background-color: ${colors.lightgray};
  border-radius: 10px;
  box-shadow: 0px 0px 1px ${colors.darkgray};
  align-items: flex-start;
  justify-content: start;
`;
const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 15px 5px 5px 5px;
`;

// Texts
const Title = styled.h3`
  font-family: ${fonts.principalBold};
  font-size: 20px;
  color: ${colors.lightblue};
  margin: 10px 0px 0px 10px;
`;
const Clarification = styled.p`
  font-family: ${fonts.principal};
  color: ${colors.black};
  font-size: 12px;
  margin: 10px 0px 5px 10px;
`;
const Error = styled.p`
  text-align: center;
  font-family: ${fonts.principalBold};
  color: ${colors.red};
  font-size: 14px;
  margin: 4px 0px 4px 10px;
`;
const TextBottom = styled(Clarification)`
  margin: 60px 0px 0px 10px;
`;

// Inputs
const Input = styled.input`
  width: 90%;
  min-height: 25px;
  font-size: 1em;
  font-family: ${fonts.principalBold};
  padding: 5px 20px;
  text-align: flex-start;
  border-radius: 10px;
  border: 0.5px solid ${colors.black};
  background-color: ${colors.white};
  color: ${colors.black};
  margin-left: 10px;
  outline: none;
`;
const Label = styled.label`
  cursor: pointer;
  width: 90%;
  font-size: 1em;
  font-family: ${fonts.principalBold};
  padding: 10px 20px;
  text-align: center;
  border-radius: 10px;
  border: 0.5px solid ${colors.black};
  background-color: ${colors.white};
  color: ${colors.darkgray};
  margin-left: 10px;
`;
const InputFile = styled.input`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;
const Button = styled.input`
  cursor: pointer;
  background-color: ${colors.skyBlue};
  height: 40px;
  border-radius: 5px;
  font-family: ${fonts.principalBold};
  font-size: 20px;
  color: ${colors.white};
  margin: 20px 5px 0px 5px;
  box-shadow: 2px 2px 2px ${colors.darkgray};
  align-self: center;
  width: 90%;
  :disabled {
    cursor: default;
    background-color: ${colors.gray};
  }
`;

const Eye = styled(FaEye)`
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
  transition: all 1s;
  opacity: ${({ active }) => (!active ? '0.5' : '1')};
`;

export default Register;
