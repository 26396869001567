// REFACTOR/TODO
// Principal libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';

// Components
import Stars from '../../../components/Stars';
import More from './more/More';
import Contract from './contract/Contract';
import Schedule from './schedule/Schedule';

// Extras
import { MariaDbToDate } from '../../../utils/dates';
import actions from '../../../actions/account';
import { selectors, actions as accountActions } from '../../../reducers/account';
import { fonts } from '../../../utils/constants';

const Detail = ({ item, setModal, modal }) => {
  const dispatch = useDispatch();
  const { info, isLogged } = useSelector((state) => ({
    info: selectors.getInfo(state),
    isLogged: selectors.isLogged(state)
  }));
  const [stepState, setStep] = useState(0);

  useEffect(() => {
    if (item) {
      dispatch(actions.getInfo({ identify: item.id }));
    }
  }, []);

  const handleMore = () => {
    setStep(1);
    setModal({ ...modal, title: 'Mas información' });
  };

  const handleContract = () => {
    if (isLogged) {
      setStep(2);
      setModal({ ...modal, title: 'Materias disponibles' });
    } else {
      dispatch(accountActions.showLogin(true));
    }
  };

  const handleSchedule = () => {
    if (isLogged) {
      setStep(3);
      setModal({ ...modal, title: 'Horario a convenir' });
    } else {
      dispatch(accountActions.showLogin(true));
    }
  };

  return (
    <Container>
      <Header>
        {stepState === 0 ? (
          <>
            <ContentHeader>
              <Name>{`${item.name} ${item.surname}`}</Name>
              <Photo src={item.photo} />
              <Stars quantity={item.rate} size={24} />
            </ContentHeader>
            <ContentHeader>
              <Line>
                <Text>En Estuap desde:</Text>
                <TextDesc>{MariaDbToDate(item.date)}</TextDesc>
              </Line>
              <Line>
                <Text>Departamento: </Text>
                <TextDesc>{item.departament}</TextDesc>
              </Line>
              <Line>
                <Text>Valoraciones: </Text>
                <TextDesc>{item.ratecount}</TextDesc>
              </Line>
              <Line>
                <Text>Media del precio por hora: </Text>
                <TextDesc>{item.price}</TextDesc>
              </Line>
            </ContentHeader>
          </>
        ) : null}
      </Header>
      {stepState === 0 ? (
        <>
          <Title left>Sobre mi</Title>
          <Input rows={3} disabled value={info.about ? info.about : ''} />
          <MoreInfoButton onClick={handleMore}>
            Mas información
            <Search color="#95cdff" size={30} />
          </MoreInfoButton>
          <Border />
          {/*
            item.fixPrice && (
              <ContractButton onClick={handleContract}>
                Horario fijo
              </ContractButton>
            )
            */}
          {item.price && (
            <ContractButton onClick={handleSchedule}>
              Horario a convenir
            </ContractButton>
          )}
        </>
      ) : null}
      {stepState === 1 ? <More info={info} /> : null}
      {stepState === 2 ? (
        <Contract item={item} setStep={setStep} modal={modal} setModal={setModal} />
      ) : null}
      {stepState === 3 ? (
        <Schedule
          item={item}
          setStep={setStep}
          modal={modal}
          setModal={setModal}
          data={info}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Name = styled.p`
  text-transform: uppercase;
  text-align: center;
  color: #0e67be;
  font-weight: bold;
  font-size: 0.9em;
`;

const Title = styled.p`
  text-transform: uppercase;
  text-align: left;
  width: 90%;
  color: #0e67be;
  font-weight: bold;
  font-size: 0.8em;
`;

const Photo = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 100px;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  text-transform: uppercase;
  color: #95cdff;
  font-weight: bold;
  font-size: 0.8em;
`;

const TextDesc = styled.p`
  text-transform: uppercase;
  margin-left: 5px;
  color: #0e67be;
  font-size: 0.8em;
  margin-left: 5px;
  font-weight: bold;
`;

const Input = styled.textarea`
  min-height: 50px;
  max-height: 100px;
  width: 90%;
  background-color: #1d233b;
  color: white;
  font-family: ${fonts.principal};
  font-size: 1em;
  padding: 10px;
  resize: none;
  border: 1px solid #bac4f2;
  border-radius: 5px;
`;

const Search = styled(FaInfoCircle)`
  margin-left: 5px;
`;

const MoreInfoButton = styled.button`
  background-color: transparent;
  color: #95cdff;
  outline: none;
  border: 1px solid white;
  border-radius: 20px;
  padding: 15px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1em;
  font-family: ${fonts.principal};
  cursor: pointer;
`;

const Border = styled.div`
  width: 85%;
  height: 2px;
  border-radius: 10px;
  background-color: white;
  margin: 30px 0;
`;

const ContractButton = styled.button`
  background-color: #0e67be;
  color: white;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 80%;
  font-family: ${fonts.principal};
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  margin: 15px 0;
`;

export default Detail;
