import { LINKED_ACCOUNTS_NUMBERS } from '../utils/constants';

const UPDATE_TYPES = {
  ACCOUNT_UPDATE_INFO_REQUEST: 'account/UPDATE/ACCOUNT_INFO_REQUEST',
  ACCOUNT_UPDATE_INFO_FAILURE: 'account/UPDATE/ACCOUNT_INFO_FAILURE',
  ACCOUNT_UPDATE_INFO_SUCCESS: 'account/UPDATE/ACCOUNT_INFO_SUCCESS',
  ACCOUNT_SET_LINKED_ACCOUNT: 'account/ACCOUNT_SET_LINKED_ACCOUNT'
};

const LOCATION_TYPES = {
  ACCOUNT_LOCATION_REQUEST: 'account/ACCOUNT_LOCATION_REQUEST',
  ACCOUNT_LOCATION_FAILURE: 'account/ACCOUNT_LOCATION_FAILURE',
  ACCOUNT_LOCATION_SUCCESS: 'account/ACCOUNT_LOCATION_SUCCESS'
};

const SESSION_TYPES = {
  ACCOUNT_UPDATE_SESSION_REQUEST: 'account/UPDATE/ACCOUNT_SESSION_REQUEST',
  ACCOUNT_UPDATE_SESSION_FAILURE: 'account/UPDATE/ACCOUNT_SESSION_FAILURE',
  ACCOUNT_UPDATE_SESSION_SUCCESS: 'account/UPDATE/ACCOUNT_SESSION_SUCCESS'
};

const PAYMENT_TYPES = {
  ACCOUNT_PAYMENT_REQUEST: 'students/ACCOUNT_PAYMENT_REQUEST',
  ACCOUNT_PAYMENT_FAILURE: 'students/ACCOUNT_PAYMENT_FAILURE',
  ACCOUNT_PAYMENT_SUCCESS: 'students/ACCOUNT_PAYMENT_SUCCESS'
};

const STUDENT_ACCOUNT = {
  STUDENTS_SAVE_REQUEST: 'students/STUDENTS_SAVE_REQUEST',
  STUDENTS_SAVE_FAILURE: 'students/STUDENTS_SAVE_FAILURE',
  STUDENTS_SAVE_SUCCESS: 'students/STUDENTS_SAVE_SUCCESS',
  STUNDETS_LOGGED: 'students/STUDENTS_LOGGED'
};

const REPORT_PROBLEM = {
  REPORT_PROBLEM_REQUEST: 'reports/REPORT_PROBLEM_REQUEST',
  REPORT_PROBLEM_SUCCESS: 'reports/REPORT_PROBLEM_SUCCESS',
  REPORT_PROBLEM_FAILURE: 'reports/REPORT_PROBLEM_FAILURE'
};

const CHAT_TYPES = {
  TEACHERS_CHAT_REQUEST: 'teachers/TEACHERS_CHAT_REQUEST',
  TEACHERS_CHAT_FAILURE: 'teachers/TEACHERS_CHAT_FAILURE',
  TEACHERS_CHAT_SUCCESS: 'teachers/TEACHERS_CHAT_SUCCESS',
  TEACHERS_SEND_MESSAGE_REQUEST: 'teachers/TEACHERS_SEND_MESSAGE_REQUEST',
  TEACHERS_SEND_MESSAGE_FAILURE: 'teachers/TEACHERS_SEND_MESSAGE_FAILURE',
  TEACHERS_SEND_MESSAGE_SUCCESS: 'teachers/TEACHERS_SEND_MESSAGE_SUCCESS',
  TEACHERS_CHANNEL_REQUEST: 'teachers/TEACHERS_CHANNEL_REQUEST',
  TEACHERS_CHANNEL_FAILURE: 'teachers/TEACHERS_CHANNEL_FAILURE',
  TEACHERS_CHANNEL_SUCCESS: 'teachers/TEACHERS_CHANNEL_SUCCESS'
};

export const types = {
  ACCOUNT_DELETE_REQUEST: 'account/ACCOUNT_DELETE_REQUEST',
  ACCOUNT_DELETE_FAILURE: 'account/ACCOUNT_DELETE_FAILURE',
  ACCOUNT_DELETE_SUCCESS: 'account/ACCOUNT_DELETE_SUCCESS',
  ACCOUNT_CLEAN_MESSAGE: 'account/ACCOUNT_CLEAN_MESSAGE',
  ACCOUNT_SET_TOKEN: 'account/ACCOUNT_SET_TOKEN',
  ACCOUNT_DEL_TOKEN: 'account/ACCOUNT_DEL_TOKEN',
  ACCOUNT_SET_REGISTER_TOKEN: 'account/ACCOUNT_SET_REGISTER_TOKEN',
  ACCOUNT_DEL_REISTER_TOKEN: 'account/ACCOUNT_DEL_REGISTER_TOKEN',
  ACCOUNT_INFO_REQUEST: 'account/ACCOUNT_INFO_REQUEST',
  ACCOUNT_INFO_FAILURE: 'account/ACCOUNT_INFO_FAILURE',
  ACCOUNT_INFO_SUCCESS: 'account/ACCOUNT_INFO_SUCCESS',
  ACCOUNT_UPDATE_NOTIFICATION_OPTIONS: 'account/ACCOUNT_UPDATE_NOTIFICATION_OPTIONS',
  ACCOUNT_VERIFY_USERNAME_REQUEST: 'account/ACCOUNT_VERIFY_USERNAME_REQUEST',
  ACCOUNT_VERIFY_USERNAME_FAILURE: 'account/ACCOUNT_VERIFY_USERNAME_FAILURE',
  ACCOUNT_VERIFY_USERNAME_SUCCESS: 'account/ACCOUNT_VERIFY_USERNAME_SUCCESS',
  ACCOUNT_RESET_PASSWORD_REQUEST: 'account/ACCOUNT_RESET_PASSWORD_REQUEST',
  ACCOUNT_RESET_PASSWORD_FAILURE: 'account/ACCOUNT_RESET_PASSWORD_FAILURE',
  ACCOUNT_RESET_PASSWORD_SUCCESS: 'account/ACCOUNT_RESET_PASSWORD_SUCCESS',
  ACCOUNT_SET_STATE_TUTORIAL: 'account/ACCOUNT_SET_STATE_TUTORIAL',
  ACCOUNT_SHOW_LOGIN: 'account/ACCOUNT_SHOW_LOGIN',
  ACCOUNT_UPDATE_MODAL: 'account/ACCOUNT_UPDATE_MODAL',
  ACCOUNT_SET_LAST_STREAM: 'account/ACCOUNT_SET_LAST_STREAM',
  ...UPDATE_TYPES,
  ...LOCATION_TYPES,
  ...SESSION_TYPES,
  ...STUDENT_ACCOUNT,
  ...CHAT_TYPES,
  ...PAYMENT_TYPES,
  ...REPORT_PROBLEM
};

const INITIAL_UPDATE_STATE = {
  error: '',
  success: ''
};

const INITIAL_CHAT_STATE = {
  channels: [],
  chats: []
};

export const INITIAL_STATE = {
  currentLocation: {
    latitude: -34.8950229,
    longitude: -56.1526626
  },
  notificationOptions: {
    disableNotifications: false
  },
  info: {},
  isFetching: false,
  message: null,
  token: null,
  registerToken: null,
  logged: false,
  account: {},
  modal: { show: false, text: null },
  lastStream: null,
  linkedAccount: LINKED_ACCOUNTS_NUMBERS.NONE,
  payments: [],
  tutorials: {
    createFixed: {
      label: 'Crear horario fijo',
      value: true
    }
  },
  ...INITIAL_UPDATE_STATE,
  ...INITIAL_CHAT_STATE
};

export default (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = !state ? INITIAL_STATE : state;
  const { info } = action;
  switch (action.type) {
    // FOR FETCH INFO
    case types.ACCOUNT_INFO_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.ACCOUNT_INFO_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.ACCOUNT_INFO_SUCCESS:
      return { ...state, isFetching: false, info: action.info };
    case types.ACCOUNT_PAYMENT_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.ACCOUNT_PAYMENT_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.ACCOUNT_PAYMENT_SUCCESS:
      return { ...state, isFetching: false, payments: action.payments };
    case types.ACCOUNT_VERIFY_USERNAME_REQUEST:
      return { ...state, isFetching: true };
    case types.ACCOUNT_VERIFY_USERNAME_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.ACCOUNT_VERIFY_USERNAME_SUCCESS:
      return { ...state, isFetching: false };
    // FOR UPDATE INFO
    case types.ACCOUNT_UPDATE_INFO_REQUEST:
      return { ...state, isFetching: true, error: '', success: '' };
    case types.ACCOUNT_UPDATE_INFO_FAILURE:
      return { ...state, isFetching: false, error: action.message };
    case types.ACCOUNT_UPDATE_INFO_SUCCESS:
      return { ...state, isFetching: false, success: action.message, info };
    // FOR GET LOCATION
    case types.ACCOUNT_LOCATION_REQUEST:
      return { ...state, message: '' };
    case types.ACCOUNT_LOCATION_FAILURE:
      return { ...state, message: action.message };
    case types.ACCOUNT_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentLocation: action.currentLocation
      };
    case types.ACCOUNT_UPDATE_SESSION_REQUEST:
      return { ...state, message: '' };
    case types.ACCOUNT_UPDATE_SESSION_FAILURE:
      return { ...state, message: action.message };
    case types.ACCOUNT_UPDATE_SESSION_SUCCESS:
      return { ...state };
    case types.ACCOUNT_SET_TOKEN:
      return { ...state, token: action.token };
    case types.ACCOUNT_DEL_TOKEN:
      return { ...state, token: null };
    case types.ACCOUNT_SET_REGISTER_TOKEN:
      return {
        ...state,
        registerToken: action.registerToken,
        isFetching: false
      };
    case types.ACCOUNT_DEL_REGISTER_TOKEN:
      return { ...state, registerToken: null };
    // FOR STUDENT ACCOUNT
    case types.STUDENTS_SAVE_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.STUDENTS_SAVE_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.STUDENTS_SAVE_SUCCESS:
      return { ...state, isFetching: false, account: action.account };
    case types.STUNDETS_LOGGED: {
      if (!action.logged) {
        return { ...state, logged: false, account: {} };
      }
      return { ...state, logged: true };
    }
    // FOR CHAT TYPES
    case types.TEACHERS_CHANNEL_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_CHANNEL_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_CHANNEL_SUCCESS:
      return { ...state, isFetching: false, channels: action.channels };
    case types.TEACHERS_CHAT_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.TEACHERS_CHAT_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.TEACHERS_CHAT_SUCCESS:
      return { ...state, isFetching: false, chats: action.chats };
    case types.TEACHERS_SEND_MESSAGE_REQUEST:
      return { ...state, message: '' };
    case types.TEACHERS_SEND_MESSAGE_FAILURE:
      return { ...state, message: action.message };
    case types.TEACHERS_SEND_MESSAGE_SUCCESS:
      return { ...state, chats: action.chats };
    // LINKED ACCOUNTS
    case types.ACCOUNT_SET_LINKED_ACCOUNT:
      return { ...state, linkedAccount: action.linkedAccount };
    // REPORTS
    case types.REPORT_PROBLEM_REQUEST:
      return { ...state, isFetching: true, message: '' };
    case types.REPORT_PROBLEM_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.REPORT_PROBLEM_SUCCESS:
      return { ...state, isFetching: false, message: action.message };
    case types.ACCOUNT_CLEAN_MESSAGE:
      return { ...state, message: null };
    case types.ACCOUNT_UPDATE_NOTIFICATION_OPTIONS:
      return { ...state, notificationOptions: action.notificationOptions };
    case types.ACCOUNT_RESET_PASSWORD_REQUEST:
      return { ...state, isFetching: true };
    case types.ACCOUNT_RESET_PASSWORD_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.ACCOUNT_RESET_PASSWORD_SUCCESS:
      return { ...state, isFetching: false };
    case types.ACCOUNT_DELETE_REQUEST:
      return { ...state, isFetching: true };
    case types.ACCOUNT_DELETE_FAILURE:
      return { ...state, isFetching: false, message: action.message };
    case types.ACCOUNT_DELETE_SUCCESS:
      return INITIAL_STATE;
    case types.ACCOUNT_SET_STATE_TUTORIAL:
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          [action.payload.key]: {
            ...state.tutorials[action.payload.key],
            value: action.payload.value
          }
        }
      };
    case types.ACCOUNT_SHOW_LOGIN:
      return { ...state, showLogin: action.show };
    case types.ACCOUNT_UPDATE_MODAL:
      return { ...state, modal: { ...state.modal, ...action.modal } };
    case types.ACCOUNT_SET_LAST_STREAM:
      return {
        ...state,
        lastStream: { time: new Date().getTime(), id: action.id }
      };
    default:
      return state;
  }
};

const UPDATE_ACTIONS = {
  updateInfoRequest: () => ({
    type: types.ACCOUNT_UPDATE_INFO_REQUEST
  }),
  updateInfoFailure: (message) => ({
    type: types.ACCOUNT_UPDATE_INFO_FAILURE,
    message
  }),
  updateInfoSuccess: (message, info) => ({
    type: types.ACCOUNT_UPDATE_INFO_SUCCESS,
    message,
    info
  }),
  updateLanguageRequest: () => ({
    type: types.ACCOUNT_UPDATE_INFO_LANGUAGE_REQUEST
  }),
  updateLanguageFailure: (message) => ({
    type: types.ACCOUNT_UPDATE_INFO_LANGUAGE_FAILURE,
    message
  }),
  updateLanguageSuccess: (message, info) => ({
    type: types.ACCOUNT_UPDATE_INFO_LANGUAGE_SUCCESS,
    message,
    info
  }),
  updateSessionRequest: () => ({
    type: types.ACCOUNT_UPDATE_SESSION_REQUEST
  }),
  updateSessionFailure: (message) => ({
    type: types.ACCOUNT_UPDATE_SESSION_FAILURE,
    message
  }),
  updateSessionSuccess: (message) => ({
    type: types.ACCOUNT_UPDATE_SESSION_SUCCESS,
    message
  }),
  setToken: (token) => ({
    type: types.ACCOUNT_SET_TOKEN,
    token
  }),
  delToken: () => ({
    type: types.ACCOUNT_DEL_TOKEN
  }),
  setRegisterToken: (registerToken) => ({
    type: types.ACCOUNT_SET_REGISTER_TOKEN,
    registerToken
  }),
  delRegisterToken: () => ({
    type: types.ACCOUNT_DEL_REGISTER_TOKEN
  }),
  setLinkedAccount: (linkedAccount) => ({
    type: types.ACCOUNT_SET_LINKED_ACCOUNT,
    linkedAccount
  }),
  reportProblemRequest: () => ({
    type: types.REPORT_PROBLEM_REQUEST
  }),
  reportProblemFailure: (message) => ({
    type: types.REPORT_PROBLEM_FAILURE,
    message
  }),
  reportProblemSuccess: (message) => ({
    type: types.REPORT_PROBLEM_SUCCESS,
    message
  }),
  updateNotificationOptions: (notificationOptions) => ({
    type: types.ACCOUNT_UPDATE_NOTIFICATION_OPTIONS,
    notificationOptions
  })
};

const STUDENT_ACCTIONS = {
  saveStudentsRequest: () => ({
    type: types.STUDENTS_SAVE_REQUEST
  }),
  saveStudentsFailure: (message) => ({
    type: types.STUDENTS_SAVE_FAILURE,
    message
  }),
  saveStudentsSuccess: (params) => ({
    type: types.STUDENTS_SAVE_SUCCESS,
    account: params && { ...params }
  }),
  logged: (logged) => ({
    type: types.STUNDETS_LOGGED,
    logged
  })
};

const CHAT_ACTIONS = {
  channelRequest: () => ({
    type: types.TEACHERS_CHANNEL_REQUEST
  }),
  channelFailure: (message) => ({
    type: types.TEACHERS_CHANNEL_FAILURE,
    message
  }),
  channelSuccess: (channels) => ({
    type: types.TEACHERS_CHANNEL_SUCCESS,
    channels
  }),
  chatRequest: () => ({
    type: types.TEACHERS_CHAT_REQUEST
  }),
  chatFailure: (message) => ({
    type: types.TEACHERS_CHAT_FAILURE,
    message
  }),
  chatSuccess: (chats) => ({
    type: types.TEACHERS_CHAT_SUCCESS,
    chats
  }),
  sendMessageRequest: () => ({
    type: types.TEACHERS_SEND_MESSAGE_REQUEST
  }),
  sendMessageFailure: (message) => ({
    type: types.TEACHERS_SEND_MESSAGE_FAILURE,
    message
  }),
  sendMessageSuccess: (chats) => ({
    type: types.TEACHERS_SEND_MESSAGE_SUCCESS,
    chats
  })
};

export const actions = {
  infoRequest: () => ({
    type: types.ACCOUNT_INFO_REQUEST
  }),
  infoFailure: (message) => ({
    type: types.ACCOUNT_INFO_FAILURE,
    message
  }),
  infoSuccess: (info) => ({
    type: types.ACCOUNT_INFO_SUCCESS,
    info
  }),
  verifyUsernameRequest: () => ({
    type: types.ACCOUNT_VERIFY_USERNAME_REQUEST
  }),
  verifyUsernameFailure: (message) => ({
    type: types.ACCOUNT_VERIFY_USERNAME_FAILURE,
    message
  }),
  verifyUsernameSuccess: () => ({
    type: types.ACCOUNT_VERIFY_USERNAME_SUCCESS
  }),
  locationRequest: () => ({
    type: types.ACCOUNT_LOCATION_REQUEST
  }),
  locationFailure: (message) => ({
    type: types.ACCOUNT_LOCATION_FAILURE,
    message
  }),
  locationSuccess: (currentLocation) => ({
    type: types.ACCOUNT_LOCATION_SUCCESS,
    currentLocation
  }),
  paymentsRequest: () => ({
    type: types.ACCOUNT_PAYMENT_REQUEST
  }),
  paymentsFailure: (message) => ({
    type: types.ACCOUNT_PAYMENT_FAILURE,
    message
  }),
  paymentsSuccess: (payments) => ({
    type: types.ACCOUNT_PAYMENT_SUCCESS,
    payments
  }),
  resetPasswordRequest: () => ({
    type: types.ACCOUNT_RESET_PASSWORD_REQUEST
  }),
  resetPasswordFailure: (message) => ({
    type: types.ACCOUNT_RESET_PASSWORD_FAILURE,
    message
  }),
  resetPasswordSuccess: () => ({
    type: types.ACCOUNT_RESET_PASSWORD_SUCCESS
  }),
  reset: () => ({
    type: types.ACCOUNT_CLEAN_MESSAGE
  }),
  setStateTutorial: (key, value) => ({
    type: types.ACCOUNT_SET_STATE_TUTORIAL,
    payload: { key, value }
  }),
  deleteAccountRequest: () => ({
    type: types.ACCOUNT_DELETE_REQUEST
  }),
  deleteAccountFailure: (message) => ({
    type: types.ACCOUNT_DELETE_FAILURE,
    message
  }),
  deleteAccountSuccess: () => ({
    type: types.ACCOUNT_DELETE_SUCCESS
  }),
  showLogin: (show) => ({
    type: types.ACCOUNT_SHOW_LOGIN,
    show
  }),
  updateModal: (modal) => ({
    type: types.ACCOUNT_UPDATE_MODAL,
    modal
  }),
  setLastStream: (id) => ({
    type: types.ACCOUNT_SET_LAST_STREAM,
    id
  }),
  ...UPDATE_ACTIONS,
  ...STUDENT_ACCTIONS,
  ...CHAT_ACTIONS
};

const UPDATE_SELECTORS = {
  getError: ({ account }) => account.error,
  getSuccess: ({ account }) => account.success
};

const STUDENTS_SELECTORS = {
  getAccount: ({ account }) => account.account,
  isLogged: ({ account }) => account.logged
};

const CHAT_SELECTORS = {
  getChannels: ({ account }) => account.channels,
  getChats: ({ account }) => account.chats
};

export const selectors = {
  getNotificationOptions: ({ account }) => account.notificationOptions,
  getToken: ({ account }) => account.token,
  getRegisterToken: ({ account }) => account.registerToken,
  getCurrentLocation: ({ account }) => account.currentLocation,
  getInfo: ({ account }) => account.info,
  isFetching: ({ account }) => account.isFetching,
  getMessage: ({ account }) => account.message,
  getLinkedAccount: ({ account }) => account.linkedAccount,
  getPayments: ({ account }) => account.payments,
  getSingleTutorial: ({ account }, key) => account.tutorials[key],
  getTutorials: ({ account }) => account.tutorials,
  showLogin: ({ account }) => account.showLogin,
  showModal: ({ account }) => account.modal,
  getLastStream: ({ account }) => account.lastStream,
  ...UPDATE_SELECTORS,
  ...STUDENTS_SELECTORS,
  ...CHAT_SELECTORS
};
