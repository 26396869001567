import { PORT, IP, PROTOCOL } from './constants';
import { store } from '../store';

const createFormData = (photo, body = {}) => {
  const data = new FormData();
  data.append('photo', photo);
  /**  data.append('photo', {
    name: photo.fileName,
    type: photo.type,
    uri: photo.uri
  } )*/
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });
  return data;
};

export const uploadImage = async (photo) => {
  const header = {};
  if (store.getState().account.token)
    header['X-Access-Token'] = store.getState().account.token;
  if (store.getState().account.registerToken)
    header['register-access-token'] = store.getState().account.registerToken;
  return fetch(`${PROTOCOL}://${IP}:${PORT}/api/file/image`, {
    method: 'POST',
    headers: header,
    body: createFormData(photo)
  }).then(async (response) => {
    const json = await response.json();
    if (json.status !== 200) throw json.error;
    return json;
  });
};

export default uploadImage;
