// Principal libraries
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

//Navigator
import Router from './routes/Router';

// Utils
import { store, persistor } from './store';
import actions from './actions/account';

const App = () => {
  useEffect(() => {
    store.dispatch(actions.requestRegisterToken());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  );
};

export default App;
