// Principal libraries
import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../utils/constants';

const InputBox = (props) => (
  <Container>
    <Label>{props.title}:</Label>
    {props.type === 'textarea' ? (
      <InputArea {...props} />
    ) : (
      <Input type="text" {...props} />
    )}
  </Container>
);

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
`;

const Label = styled.label`
  text-align: left;
  width: 100px;
  margin: 2px;
  font-weight: bold;
  color: #0e67be;
`;

const Input = styled.input`
  flex: 1;
  height: 40px;
  outline: none;
  font-size: 1em;
  font-family: ${fonts.principalBold};
  padding: 5px 20px;
  text-align: center;
  border-radius: 10px;
  border: 0.5px solid ${colors.black};
  background-color: ${colors.white};
  color: black;
`;

const InputArea = styled.textarea`
  flex: 1;
  height: 80px;
  outline: none;
  font-size: 1em;
  font-family: ${fonts.principalBold};
  padding: 5px 20px;
  text-align: center;
  border-radius: 10px;
  border: 0.5px solid ${colors.black};
  background-color: ${colors.white};
  color: black;
`;

export default InputBox;
