// Principal libraries
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const types = ['Total', 'A convenir', 'Por hora'];
const typesValue = ['t', 's', 'h'];

const TypeRadio = ({ type, onChange, disabled }) => {
  const [index, setIndex] = useState(-1);

  const handlePress = (i) => () => {
    setIndex(i);
    onChange(typesValue[i]);
  };

  useEffect(() => {
    if (type) {
      let i = -1;
      switch (type) {
        case 't':
          i = types.indexOf('Total');
          break;
        case 's':
          i = types.indexOf('A convenir');
          break;
        case 'h':
          i = types.indexOf('Por hora');
          break;
        default:
          break;
      }
      if (i !== -1) {
        handlePress(i, typesValue[i])();
      }
    }
  }, [type]);

  return (
    <View>
      {types.map((gender, i) => {
        return (
          <Touchable
            disabled={disabled}
            selected={i === index}
            key={gender}
            onClick={handlePress(i)}
          >
            {gender}
          </Touchable>
        );
      })}
    </View>
  );
};

const View = styled.div`
  flex-direction: 'row';
  width: '100%';
  flex-wrap: 'wrap';
  justify-content: 'center';
  margin-vertical: 20;
`;

const Touchable = styled.button`
  background-color: white;
  border-width: 1;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px;
  background-color: ${(props) =>
    props.disabled ? 'darkgray' : props.selected ? '#333C5D' : '#1692FF'};
  border-color: ${(props) =>
    props.disabled ? 'darkgray' : props.selected ? '#333C5D' : '#1692FF'};
  outline: none;
  cursor: pointer;
  color: white;
  font-weight: bold;
  transition: all 1s;
`;

export default TypeRadio;
