// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { MdStar, MdStarHalf } from 'react-icons/md';

const renderStars = (stars, size) => {
  const icons = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.round(stars); i++) {
    if (i === Math.round(stars) - 1 && i + 1 > stars) {
      icons.push(<MiddleStar key={i} size={size} color="#0E67BE" />);
    } else {
      icons.push(<TotalStar key={i} size={size} color="#0E67BE" />);
    }
  }
  return icons;
};

const Stars = ({ quantity, size = 28, zMargin }) => (
  <Container zMargin={zMargin}>
    {quantity ? (
      renderStars(quantity, size).map((star) => star)
    ) : (
      <p>Sin estrellas</p>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: ${(props) => (props.zMargin ? '0' : '15px 0px')};
`;

const TotalStar = styled(MdStar)`
  margin: 0px 5px;
`;

const MiddleStar = styled(MdStarHalf)`
  margin: 0px 5px;
`;

export default Stars;
