import * as API from './api';

const URL = '/api/students';

export const setStudents = (params) => API.executeAnonymous(URL, params);

export const get = (params) => API.executeAnonymous(`${URL}/get`, params);

export const verify = (params) => API.executeAnonymous(`${URL}/verify`, params);

export const modify = (params) => API.executeAnonymous(`${URL}/modify`, params);

export const modifyPassword = (params) =>
  API.executeAnonymous(`${URL}/modifyPassword`, params);
