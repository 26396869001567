// Principal libraries
import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../../../utils/constants';

const InputBox = (props) => (
  <Container>
    <Label>{props.title}:</Label>
    <Input type="text" {...props} autocorrect="off" autocapitalize="none" />
  </Container>
);

const Container = styled.div`
  margin: 10px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  text-align: left;
  width: 100px;
  margin: 2px;
  font-weight: bold;
  color: #0e67be;
`;

const Input = styled.input`
  flex: 1;
  outline: none;
  font-size: 1em;
  font-family: ${fonts.principal};
  padding: 5px 20px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid gray;
  background-color: transparent;
  color: black;
`;

export default InputBox;
