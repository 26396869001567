import * as API from './api';

const URL = '/api/teachers';

export const getTeachers = (params) => API.executeAnonymous(URL, params);

export const setTeacher = (params) => API.executeAnonymous(`${URL}/set`, params);

export const registerSchoolTeacher = (params) => API.executeAnonymous(`${URL}/registerSchoolTeacher`, params);

export const getRate = (params) => API.executeAnonymous(`${URL}/getRate`, params);

export const getAgreeSchedule = (params) =>
  API.executeAnonymous(`${URL}/getAgreeSchedule`, params);

export const getInfo = (params) => API.executeAnonymous(`${URL}/getInfo`, params);

export const getTeacherInfo = (params) =>
  API.executeAnonymous(`${URL}/getTeacherInfo`, params);

export const modify = (params) => API.executeAnonymous(`${URL}/modify`, params);

export const getCategories = (params) =>
  API.executeAnonymous(`${URL}/getCategories`, params);

export const createFixedHour = (params) =>
  API.executeAnonymous(`${URL}/createFixedHour`, params);

export const getFixed = (params) => API.executeAnonymous(`${URL}/getFixed`, params);

export const getLanguages = (params) =>
  API.executeAnonymous(`${URL}/getLanguages`, params);

export const createLanguage = (params) =>
  API.executeAnonymous(`${URL}/createLanguage`, params);

export const getNearestTeachers = (params) =>
  API.executeAnonymous(`${URL}/getNearestTeachers`, params);

export const createReservation = (params) =>
  API.executeAnonymous(`${URL}/createReservation`, params);

export const deleteCategory = (params) =>
  API.executeAnonymous(`${URL}/deleteCategory`, params);

export const addCategories = (params) =>
  API.executeAnonymous(`${URL}/addCategories`, params);

export const modifyClass = (params) =>
  API.executeAnonymous(`${URL}/modifyClass`, params);

export const getChannels = (params) =>
  API.executeAnonymous(`${URL}/getChannels`, params);

export const getChats = (params) => API.executeAnonymous(`${URL}/getChats`, params);

export const sendMessage = (params) =>
  API.executeAnonymous(`${URL}/sendMessage`, params);

export const getPayments = (params) =>
  API.executeAnonymous(`${URL}/getPayments`, params);

export const getReservationDates = (params) =>
  API.executeAnonymous(`${URL}/getReservationDates`, params);

export const getReservations = (params) =>
  API.executeAnonymous(`${URL}/getReservations`, params);

export const getReservationProfileById = (params) =>
  API.executeAnonymous(`${URL}/getReservationProfileById`, params);

export const deleteTeacher = (params) =>
  API.executeAnonymous(`${URL}/deleteTeacher`, params);
