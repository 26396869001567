import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../utils/constants';

const DownloadApp = () => (
  <>
    <StudentTitle>
      Para registrarte como alumno debes hacerlo a través de la app
    </StudentTitle>
    <StudentClarification>
      No es necesario registrarte para acceder a los streaming
    </StudentClarification>
    <a href="https://estuap.com/descarga" target="_blank">
      <Download>Descargar app</Download>
    </a>
  </>
);

const StudentTitle = styled.p`
  margin: 20px 0px 0px 0px;
  font-family: ${fonts.principalBold};
  text-align: center;
  font-size: 20px;
  color: ${colors.black};
`;

const StudentClarification = styled.p`
  font-family: ${fonts.principalBold};
  font-size: 18px;
  color: ${colors.blue};
  text-align: center;
`;

const Download = styled.button`
  outline: none;
  cursor: pointer;
  background-color: ${colors.lightblue};
  height: 60px;
  width: 300px;
  border-radius: 5px;
  font-family: ${fonts.secundary};
  font-size: 20px;
  color: ${colors.white};
  margin: 20px 5px 20px 5px;
  border: 2px solid ${colors.lightblue};
  transition: all 0.5s;
  :hover {
    background-color: ${colors.white};
    color: ${colors.lightblue};
  }
`;

export default DownloadApp;
